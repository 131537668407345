import { Component, OnInit } from '@angular/core';
import { faAngleRight, faCog, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateFloorModalComponent } from '../../../ui-components/modals/create-floor-modal/create-floor-modal.component';
import { FloorService } from '../../../../services/floor.service';
import { Floor } from '../../../../models/floor';
import { Building } from '../../../../models/building';
import { BuildingService } from '../../../../services/building.service';

@Component({
  selector: 'app-buildings-list-of-the-floors',
  templateUrl: './buildings-list-of-the-floors.component.html',
  styleUrls: ['./buildings-list-of-the-floors.component.scss']
})
export class BuildingsListOfTheFloorsComponent implements OnInit {
  public faAngleRight = faAngleRight;
  public faCog = faCog;
  public faTimesCircle = faTimesCircle;
  public editable: boolean = false;
  public isLoadingComponent: boolean = false;
  public floors: Floor[];
  public building: Building;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private floorService: FloorService,
    private buildingService: BuildingService
  ) {
  }

  ngOnInit(): void {
    this.isLoadingComponent = true;
    this.route.data.subscribe(data => {
      this.buildingService.getBuilding(data.building.id).subscribe(response => {
        this.building = response;
        this.floors =
          this.building.floors
            .filter(floor => floor.status)
            .sort((a, b) => a.id - b.id);
        this.isLoadingComponent = false;
      });
    });
  }

  addFloorModal(): void {
    const createFloorModal = this.modalService.open(CreateFloorModalComponent, {
      backdropClass: 'light-blue-backdrop',
      centered: true
    });

    createFloorModal.componentInstance.addedFloorChange.subscribe((receivedFloor) => {
      this.isLoadingComponent = true;

      const preparedToCreateFloor: Floor = {
        building: this.building.id,
        description: receivedFloor.form.value.description,
        status: 1
      };

      this.floorService.createFloor(preparedToCreateFloor).subscribe((response) => {
        const preparedToPushFloor = {
          id: response.id,
          description: response.description
        };

        this.floors.push(preparedToPushFloor);
        this.isLoadingComponent = false;
      });
    });
  }

  removeFloor(floorId): void {
    this.isLoadingComponent = true;
    this.floorService.removeFloor(floorId).subscribe(() => {
      this.removeFloorFromArray(floorId);
      this.isLoadingComponent = false;
    });
  }

  toggleEditable() {
    this.editable = !this.editable;
  }

  private removeFloorFromArray(floorId) {
    const removeIndex = this.floors.map(item => item.id).indexOf(floorId);
    this.floors.splice(removeIndex, 1);
  }
}
