import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProblemService } from '../../../../services/problem.service';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-show-problems-in-task-modal',
  templateUrl: './show-problems-in-task-modal.component.html',
  styleUrls: ['./show-problems-in-task-modal.component.scss']
})
export class ShowProblemsInTaskModalComponent implements OnInit {
  @Input() problem;

  public faTimes = faTimes;
  public isLoadingComponent: boolean = false;
  public image;

  constructor(
    public modal: NgbActiveModal,
    private problemService: ProblemService,
  ) {
  }

  ngOnInit(): void {
    this.isLoadingComponent = true;
    this.problemService
      .getProblemImage(this.problem.id)
      .pipe(finalize(() => this.isLoadingComponent = false))
      .subscribe((img) => this.image = 'data:image/jpeg;base64,' + img);
  }
}
