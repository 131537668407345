import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CheckboxesDaysComponent } from './checkboxes-days.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CheckboxesDaysComponent],
  exports: [CheckboxesDaysComponent],
  imports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule
  ]
})
export class CheckboxesDaysModule { }
