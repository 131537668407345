import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { getPaginatedResult } from './helpers/paginationHelper';
import { User } from '../models/user';
import { getFilterUsersParams } from './helpers/filterHelper';
import { SearchUserState } from '../interfaces/SearchUserState';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, delay, map, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { PaginatedResult } from '../interfaces/pagination';
import { Employee } from '../models/employee';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _search$ = new Subject<void>();
  public _state: SearchUserState = {
    page: 1,
    itemsPerPage: 6,
    userFullName: '',
    sortBy: '',
    sortByDirection: '',
    status: 1
  };
  public users: User[];

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService,
  ) {
    this._search$.pipe(
      tap(x => {
        debounceTime(200),
          this.getUsers(),
          delay(200);
      })
    ).subscribe();

    this._search$.next();
  }

  get searchTerm() {
    return this._state.userFullName;
  }

  set searchTerm(userFullName: string) {
    this._set({userFullName});
  }

  private _set(patch: Partial<SearchUserState>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  getUser(userId: string): Observable<User> {
    return this.httpClient.get<User>(this.apiService.url('users/' + userId));
  }

  createUser(data): Observable<User> {
    return this.httpClient.post<User>(this.apiService.url('users/create'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.USER.NOTIFICATIONS.USER_ADDED');
        })
      );
  }

  updateUser(userId: number, data): Observable<User> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');
    return this.httpClient.patch<User>(this.apiService.url('users/' + userId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.USER.NOTIFICATIONS.USER_UPDATED');
        })
      );
  }

  getUsers(): Observable<PaginatedResult<any>> {
    const params = getFilterUsersParams(this._state);
    return getPaginatedResult<User>(this.apiService.url('users'), params, this.httpClient);
  }

  removeUsers(ids: number[]) {
    const data = {
      items: ids,
      type: 'user'
    };

    return this.httpClient.post<User>(this.apiService.url('bulk-delete'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.USER.NOTIFICATIONS.USER_DELETED');
        })
      );
  }
}
