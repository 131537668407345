import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DefaultTask } from '../../../../models/defaultTask';
import { ValidateThreeChars } from '../../../../validators/three-chars.validator';

@Component({
  selector: 'app-create-edit-default-task-modal',
  templateUrl: './create-edit-default-task-modal.component.html',
  styleUrls: ['./create-edit-default-task-modal.component.scss']
})
export class CreateEditDefaultTaskModalComponent implements OnInit {
  public faTimes = faTimes;
  public defaultTaskCreateForm: FormGroup;
  public loadingDefaultTasks: boolean = false;
  @Input() defaultTask: DefaultTask;
  @Input() defaultTasks: DefaultTask[];
  @Output() addedDefaultTaskChange = new EventEmitter<any>();
  @Output() updatedDefaultTaskChange = new EventEmitter<any>();

  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.defaultTaskCreateForm = new FormGroup({
      description: new FormControl(this.defaultTask ? this.defaultTask.description : '',
        [
          Validators.required,
          ValidateThreeChars,
          this.defaultTaskExistValidation()
        ]),
    });
  }

  saveDefaultTask(): void {
    if (this.defaultTaskCreateForm.invalid) {
      return;
    }
    if (this.defaultTask) {
      this.updatedDefaultTaskChange.emit({
        id: this.defaultTask.id,
        description: this.defaultTaskCreateForm.value.description,
      });
    } else {
      this.addedDefaultTaskChange.emit({
        description: this.defaultTaskCreateForm.value.description,
      });
    }

    this.modal.dismiss();
  }


  defaultTaskExistValidation(): ValidatorFn {
    return (control: AbstractControl) => {
      const foundedDefaultTasks = this.defaultTasks.filter((value => {
        return value.description === control.value;
      }));

      if (foundedDefaultTasks.length > 0) {
        return { defaultTaskExistValidation: true };
      }

      return null;
    };
  }

}
