import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingSubject$.asObservable();
  public ready$: Observable<boolean> = this.loadingSubject$.asObservable().pipe(map(loading => !loading));

  public start(): void {
    if (this.loadingSubject$.value) {
      return;
    }

    this.loadingSubject$.next(true);
  }

  public stop(): void {
    if (!this.loadingSubject$.value) {
      return;
    }

    setTimeout(() => this.loadingSubject$.next(false), 500);
  }
}
