import { NgModule } from '@angular/core';
import { RangeDatepickerComponent } from './range-datepicker.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [RangeDatepickerComponent],
  exports: [RangeDatepickerComponent],
  imports: [
    FormsModule,
    // ReactiveFormsModule,
    CommonModule,
    FontAwesomeModule,
    NgbModule,
  ]
})
export class RangeDatepickerModule { }
