import { NgModule } from '@angular/core';
import { BreadcrumbModule } from '../../../components/ui-components/breadcrumb/breadcrumb.module';
import { ButtonModule } from '../../../components/ui-components/button/button.module';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsPageComponent } from './settings.page';
import { SettingsItemsDefaultTasksModule } from '../../../components/dashboard/settings/settings-items-default-tasks/settings-items-default-tasks.module';


@NgModule({
  declarations: [SettingsPageComponent],
  exports: [SettingsPageComponent],
  imports: [
    TranslateModule,
    BreadcrumbModule,
    ButtonModule,
    RouterModule,
    FontAwesomeModule,
    SettingsItemsDefaultTasksModule,
  ]
})
export class SettingsPageModule {
}
