import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../../../../ui-components/button/button.module';
import { LoadingSmallModule } from '../../../../ui-components/loading-small/loading-small.module';
import { BuildingsFloorsMapComponent } from './buildings-floors-map.component';
import { UploadMapModalModule } from '../../../../ui-components/modals/upload-map-modal/upload-map-modal.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmUploadMapModalModule } from '../../../../ui-components/modals/confirm-upload-map-modal/confirm-upload-mal-modal.module';
import { AlertModule } from '../../../../ui-components/alert/alert.module';


@NgModule({
  declarations: [BuildingsFloorsMapComponent],
  exports: [BuildingsFloorsMapComponent],
  providers: [],
  imports: [
    CommonModule,
    ButtonModule,
    LoadingSmallModule,
    TranslateModule,
    UploadMapModalModule,
    ConfirmUploadMapModalModule,
    DragDropModule,
    FontAwesomeModule,
    AlertModule
  ]
})
export class BuildingsFloorsMapModule { }
