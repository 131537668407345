<ul>
  <li>
    <a [ngClass]="{'is-disabled': this.currentPage <= 1}" (click)="previous()">
      <fa-icon [icon]="faChevronLeft"></fa-icon>
      <span>{{ 'COMPONENTS.DASHBOARD.PAGINATION.PREVIOUS_PAGE' | translate }}</span>
    </a>
  </li>
  <li class="pagination-center">
    <input (keyup)="changePageInput()" [(ngModel)]="this.pageNumber" class="form-control" type="text" value="{{ this.currentPage }}">
    <div>{{ 'COMPONENTS.DASHBOARD.PAGINATION.ONE_OF' | translate }} {{ this.totalPages }}</div>
  </li>
  <li>
    <a [ngClass]="{'is-disabled': this.currentPage >= this.lastPage}" (click)="next()">
      <span>{{ 'COMPONENTS.DASHBOARD.PAGINATION.NEXT_PAGE' | translate }}</span>
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </a>
  </li>
</ul>
