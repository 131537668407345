import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { UploadMapModalComponent } from './upload-map-modal.component';
import { LoadingSmallModule } from '../../loading-small/loading-small.module';
import { TruncateTextPipePipe } from '../../../../pipes/truncate-text-pipe.pipe';
import { AlertModule } from '../../alert/alert.module';


@NgModule({
  declarations: [UploadMapModalComponent, TruncateTextPipePipe],
  exports: [UploadMapModalComponent],
    imports: [
        TranslateModule,
        CommonModule,
        FontAwesomeModule,
        ButtonModule,
        LoadingSmallModule,
        AlertModule,
    ]
})
export class UploadMapModalModule { }
