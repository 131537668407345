import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDetailedResolver implements Resolve<User> {

  constructor(
    private userService: UserService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<User> {
    return this.userService.getUser(route.paramMap.get('user_id'));
  }

}
