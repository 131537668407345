import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { PaginatedResult } from '../interfaces/pagination';
import { getFilterDayReportsParams, getFilterDayTasksParams } from './helpers/filterHelper';
import { getPaginatedResult } from './helpers/paginationHelper';
import { SearchDayTaskState } from '../interfaces/SearchDayTaskState';
import { DayTask } from '../models/dayTask';
import { debounceTime, delay, tap } from 'rxjs/operators';
import { Task } from '../models/task';
import { SearchDayReportState } from '../interfaces/SearchDayReportState';
import { DayReport } from '../models/dayReport';

@Injectable({
  providedIn: 'root',
})
export class DayReportService {
  private _searchDayReport$ = new Subject<void>();

  public _state: SearchDayReportState = {
    page: 1,
    itemsPerPage: 8,
    'createdAt[after]': null,
    'createdAt[before]': null,
    'createdBy.id': '',
    'flower.floor.building.id': '',
    'flower.id': null,
    status: ''
  };

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService
  ) {
    this._searchDayReport$.pipe(
      tap(x => {
        debounceTime(200),
          this.getDayReports(),
          delay(200);
      })
    ).subscribe();
  }

  getReport(dayReportId: string): Observable<Task> {
    return this.httpClient.get<Task>(this.apiService.url('day_reports/' + dayReportId));
  }

  getDayReports(): Observable<PaginatedResult<any>> {
    const params = getFilterDayReportsParams(this._state);
    return getPaginatedResult<DayReport>(this.apiService.url('day_reports'), params, this.httpClient);
  }

  getReportPhoto(id: number): Observable<string> {
    return this.httpClient.get<string>(this.apiService.url(`day_reports/${id}/photo`));
  }
}
