import { NgModule } from '@angular/core';
import { BreadcrumbModule } from '../../../components/ui-components/breadcrumb/breadcrumb.module';
import { UsersCreateModule } from '../../../components/dashboard/users/users-create/users-create.module';
import { WelcomePageComponent } from './welcome.page';
import { ButtonModule } from '../../../components/ui-components/button/button.module';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [WelcomePageComponent],
  exports: [WelcomePageComponent],
  imports: [
    TranslateModule,
    BreadcrumbModule,
    UsersCreateModule,
    ButtonModule,
    RouterModule,
    FontAwesomeModule
  ]
})
export class WelcomePageModule {
}
