import { Component } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-users-index-page',
  templateUrl: './users-index.page.html',
  styleUrls: ['./users-index.page.scss'],
})
export class UsersIndexPageComponent {
  constructor(
    private authService: AuthService
  ) { }

  logout(){
    this.authService.logout();
  }
}
