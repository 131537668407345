import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { UploadFileService } from '../../../../services/uploadFile.service';
import { environment } from '../../../../../environments/environment';
import { FloorService } from '../../../../services/floor.service';
import { Floor } from '../../../../models/floor';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmUploadMapModalComponent } from '../confirm-upload-map-modal/confirm-upload-map-modal.component';
import { Config } from '../../../../../config';

@Component({
  selector: 'app-upload-map-modal',
  templateUrl: './upload-map-modal.component.html',
  styleUrls: ['./upload-map-modal.component.scss']
})
export class UploadMapModalComponent {
  @Input() floor: Floor;
  @ViewChild('mapFile') mapFile: ElementRef;
  @Output() uploadedFloorMapChange = new EventEmitter<any>();

  public faTimes = faTimes;
  public isLoadingComponent = false;
  public file: File = null;
  public imageUrl: string = null;
  public imageId: number = null;

  public typeError: boolean = false;
  public sizeError: boolean = false;
  public minResolutionError: boolean = false;
  public maxResolutionError: boolean = false;

  public allowedFormats = Config.UPLOADED_MAP_ALLOWED_FORMATS;
  public maxFileSize: number = Config.UPLOADED_MAP_IMAGE_MAX_SIZE * 1024 * 1024;
  public minImageResolutionWidth: number = Config.UPLOADED_MAP_IMAGE_MIN_WIDTH;
  public minImageResolutionHeight: number = Config.UPLOADED_MAP_IMAGE_MIN_HEIGHT;
  public maxImageResolutionWidth: number = Config.UPLOADED_MAP_IMAGE_MAX_WIDTH;
  public maxImageResolutionHeight: number = Config.UPLOADED_MAP_IMAGE_MAX_HEIGHT;
  private maxWidth = Config.COMPRESSED_MAP_IMAGE_MAX_WIDTH;
  private maxHeight = Config.COMPRESSED_MAP_IMAGE_MAX_HEIGHT;

  constructor(
    public modal: NgbActiveModal,
    private fileUploadService: UploadFileService,
    private floorService: FloorService,
    private modalService: NgbModal
  ) { }

  onChange(event) {
    this.typeError = false;
    this.sizeError = false;
    this.minResolutionError = false;

    const img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);
    img.onload = () => {
      const size = event.target.files[0].size;
      const type = event.target.files[0].type;

      // Type validation
      if (!this.allowedFormats.includes(type)) {
        this.typeError = true;

        return;
      }

      // Size validation
      if (size > this.maxFileSize) {
        this.sizeError = true;

        return;
      }

      // Resolution validation
      if (img.width < this.minImageResolutionWidth || img.height < this.minImageResolutionHeight) {
        this.minResolutionError = true;

        return;
      }

      if (img.width > this.maxImageResolutionWidth || img.height > this.maxImageResolutionHeight) {
        this.maxResolutionError = true;

        return;
      }

      const file: File = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async () => {
        this.file = await this.resize(reader.result.toString(), file);
      };

      reader.readAsDataURL(file);
    };
  }

  onConfirmUploadModal(): void {
    this.modalService.open(ConfirmUploadMapModalComponent, {
      backdropClass: 'light-blue-backdrop',
    }).result.then(() => {
      this.uploadMap();
    }, () => {});
  }

  checkUploadedMap() {
    window.open(this.imageUrl, '_blank');
  }

  private uploadMap() {
    this.isLoadingComponent = true;

    this.fileUploadService.uploadFloorMap(this.file).pipe(
      tap((response) => {
        this.imageId = response.id;
        this.imageUrl = environment.url + response.contentUrl;

        this.uploadedFloorMapChange.emit({
          imageUrl: this.imageUrl
        });

        this.operationsRelatedToUpdatingTheMap().subscribe();
      }),
    ).subscribe(() => {
      this.mapFile.nativeElement.value = '';
      this.file = null;
      this.isLoadingComponent = false;
    });
  }

  private operationsRelatedToUpdatingTheMap(): Observable<any[]> {
    // Update Floor
    const preparedFloor: Floor = {
      photo: this.imageId
    };
    const updateFloor = this.floorService.updateFloor(this.floor.id, preparedFloor);

    // Reset Point Floor
    const resetPointFloor = this.floorService.resetPointFloor(this.floor.id);

    return forkJoin([updateFloor, resetPointFloor]);
  }

  private async resize(src: string, original: File): Promise<File> {
    return new Promise<File>((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.onload = () => {
        if (image.width > this.maxWidth || image.height > this.maxHeight) {
          const isHigher = image.width < image.height;
          const scale = isHigher
            ? this.maxHeight / image.height
            : this.maxWidth / image.width;

          canvas.width = isHigher ? (image.width * scale) : this.maxWidth;
          canvas.height = isHigher ? this.maxHeight : (image.height * scale);
        } else {
          canvas.width = image.width;
          canvas.height = image.height;
        }

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        resolve(this.canvasToFile(canvas, original));
      };

      image.src = src;
    });
  }

  private canvasToFile(canvas: HTMLCanvasElement, original: File): File {
    const dataUrl = canvas.toDataURL(original.type, 1);
    const arr = dataUrl.split(',');
    const type = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], original.name, { type });
  }
}
