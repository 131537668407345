import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from '../../../ui-components/button/button.module';
import { InputWithIconModule } from '../../../ui-components/input-with-icon/input-with-icon.module';
import { PaginationModule } from '../../../ui-components/pagination/pagination.module';
import { AlertModule } from '../../../ui-components/alert/alert.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';
import { BuildingsTableComponent } from './buildings-table.component';
import { LoadingContentModule } from '../../../loading-content/loading-content.module';

@NgModule({
  declarations: [BuildingsTableComponent],
  exports: [BuildingsTableComponent],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    InputWithIconModule,
    FontAwesomeModule,
    ButtonModule,
    PaginationModule,
    AlertModule,
    ReactiveFormsModule,
    FormsModule,
    LoadingSmallModule,
    LoadingContentModule
  ]
})
export class BuildingsTableModule { }
