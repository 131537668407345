import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { LoginPageComponent } from './pages/login/login.page';
import { ForgotPasswordPageComponent } from './pages/forgot-password/forgot-password.page';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { DashboardPageComponent } from './pages/dashboard/dashboard.page';
import { NotFoundPageComponent } from './pages/not-found/not-found.page';
import { UserDetailedResolver } from './resolvers/user-detailed.resolver';
import { BuildingsShowPageComponent } from './pages/dashboard/buildings/buildings-show/buildings-show.page';
import { BuildingDetailedResolver } from './resolvers/building-detailed.resolver';
import { BuildingsCreatePageComponent } from './pages/dashboard/buildings/buildings-create/buildings-create.page';
import { BuildingsIndexPageComponent } from './pages/dashboard/buildings/buildings-index/buildings-index.page';
import { UsersIndexPageComponent } from './pages/dashboard/users/users-index/users-index.page';
import { UsersCreatePageComponent } from './pages/dashboard/users/users-create/users-create.page';
import { UsersShowPageComponent } from './pages/dashboard/users/users-show/users-show.page';
import { FloorDetailedResolver } from './resolvers/floor-detailed.resolver';
import { BuildingsFloorsShowPageComponent } from './pages/dashboard/buildings/buildings-floors/buildings-floors-show/buildings-floors-show.page';
import { WelcomePageComponent } from './pages/dashboard/welcome/welcome.page';
import { ReportsPageComponent } from './pages/dashboard/reports/reports.page';
import { SettingsPageComponent } from './pages/dashboard/settings/settings.page';
import { SettingsItemsDefaultTasksComponent } from './components/dashboard/settings/settings-items-default-tasks/settings-items-default-tasks.component';

const routes: Routes = [
  // Not Authenticated
  {
    path: 'login',
    canActivate: [NotAuthenticatedGuard],
    component: LoginPageComponent,
  },
  {
    path: 'forgot-password',
    canActivate: [NotAuthenticatedGuard],
    component: ForgotPasswordPageComponent,
  },
  {
    path: 'change-password',
    canActivate: [NotAuthenticatedGuard],
    component: ChangePasswordComponent,
  },
  {
    path: '',
    canActivate: [NotAuthenticatedGuard],
    pathMatch: 'full',
    redirectTo: '/welcome'
  },
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    component: DashboardPageComponent,
    children: [
      {
        path: 'welcome',
        children: [
          {
            path: '',
            component: WelcomePageComponent,
          }
        ]
      },
      {
        path: 'users',
        data: {
          breadcrumb: 'USERS'
        },
        children: [
          {
            path: '',
            component: UsersIndexPageComponent,
            data: {
              breadcrumb: false
            },
          },
          {
            path: 'create',
            component: UsersCreatePageComponent,
            data: {
              breadcrumb: 'CREATE_USERS'
            },
          },
          {
            path: ':user_id',
            component: UsersShowPageComponent,
            resolve: {user: UserDetailedResolver},
            data: {
              breadcrumb: (data: any) => `${data.user.userFullName}`
            },
          }
        ]
      },
      {
        path: 'buildings',
        data: {
          breadcrumb: 'BUILDINGS'
        },
        children: [
          {
            path: '',
            component: BuildingsIndexPageComponent,
            data: {
              breadcrumb: false
            },
          },
          {
            path: 'create',
            component: BuildingsCreatePageComponent,
            data: {
              breadcrumb: 'CREATE_BUILDINGS'
            },
          },
          {
            path: ':building_id',
            resolve: {building: BuildingDetailedResolver},
            data: {
              breadcrumb: (data: any) => `${data.building.name}`
            },
            children: [
              {
                path: '',
                component: BuildingsShowPageComponent,
                data: {
                  breadcrumb: false
                },
              },
              {
                path: 'floors/:floor_id',
                component: BuildingsFloorsShowPageComponent,
                resolve: {floor: FloorDetailedResolver},
                data: {
                  breadcrumb: (data: any) => `${data.floor.description}`
                },
              },
            ]
          },
        ]
      },
      {
        path: 'reports',
        data: {
          breadcrumb: 'REPORTS'
        },
        children: [
          {
            path: '',
            component: ReportsPageComponent,
            data: {
              breadcrumb: false
            },
          }
        ]
      },
      {
        path: 'settings',
        component: SettingsPageComponent,
        data: {
          breadcrumb: 'SETTINGS'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/settings/(sidebarSettings:default-tasks)'
          },
          {
            path: 'default-tasks',
            component: SettingsItemsDefaultTasksComponent,
            outlet: 'sidebarSettings',
            data: {
              breadcrumb: false
            },
          },
        ]
      },
    ]
  },

  // Not Found
  {
    path: '404',
    component: NotFoundPageComponent,
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
