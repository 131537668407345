import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { PaginatedResult } from '../../interfaces/pagination';
import { throwError } from 'rxjs';

export function getPaginatedResult<T>(url, params, http: HttpClient) {
    const paginatedResult: PaginatedResult<T> = new PaginatedResult<T>();
    return http.get<T>(url, { observe: 'response', params }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.notificationsService.error(
          [400, 401, 404].includes(error.status)
            ? 'SERVICES.AUTH.NOTIFICATIONS.BAD_CREDENTIALS'
            : 'SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR',
        );
        return throwError(error);
      }),
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
}
