<div class="card card-details-editable mt-5 mt-md-0">
  <div class="card-body">
    <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_LIST_OF_THE_FLOORS.LISTS_OF_THE_FLOORS' | translate }}</h5>
    <div class="card-content">
      <ul class="items-list" *ngIf="floors?.length > 0; else noFloors">
        <li *ngFor="let floor of floors">
          <div class="li-content" [routerLink]="[ '/buildings/', building.id, 'floors', floor.id]">
            <div class="title">
              {{ floor.description }}
            </div>
            <fa-icon class="arrow-icon" [icon]="faAngleRight"></fa-icon>
          </div>
          <fa-icon (click)="removeFloor(floor.id)" *ngIf="editable" class="remove-icon"
                   [icon]="faTimesCircle"></fa-icon>
        </li>
      </ul>

      <ng-template #noFloors>
        <app-alert
          [title]="'COMPONENTS.DASHBOARD.BUILDINGS_LIST_OF_THE_FLOORS.NO_FLOORS' | translate"
          [class]="'alert-warning mt-20 mb-20'">
        </app-alert>
      </ng-template>

      <app-button
        (click)="addFloorModal()"
        [title]="'COMPONENTS.DASHBOARD.BUILDINGS_LIST_OF_THE_FLOORS.ADD_FLOOR' | translate"
        [class]="'btn btn-sm btn-primary font-weight-bold mt-10'">
      </app-button>
    </div>

    <fa-icon class="edit-cog" [class.edit-cog--active]="editable" [icon]="faCog" (click)="toggleEditable()"></fa-icon>

    <app-loading-small
      *ngIf="isLoadingComponent"
      position="absolute"
      bottom="35px"
      right="30px">
    </app-loading-small>
  </div>
</div>
