import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from '../../../ui-components/alert/alert.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '../../../ui-components/input/input.module';
import { ButtonModule } from '../../../ui-components/button/button.module';
import { UsersCreateComponent } from './users-create.component';
import { CheckboxModule } from '../../../ui-components/checkbox/checkbox.module';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';


@NgModule({
  declarations: [UsersCreateComponent],
  exports: [UsersCreateComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    AlertModule,
    InputModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    CheckboxModule,
    LoadingSmallModule,
    TranslateModule,
  ]
})
export class UsersCreateModule { }
