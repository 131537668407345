import { faAngleLeft, faCog } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { Building } from '../../../../models/building';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BuildingService } from '../../../../services/building.service';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';

@Component({
  selector: 'app-buildings-general-information',
  templateUrl: './buildings-general-information.component.html',
  styleUrls: ['./buildings-general-information.component.scss']
})
export class BuildingsGeneralInformationComponent implements OnInit {
  public faAngleLeft = faAngleLeft;
  public faCog = faCog;
  public building: Building;
  public floorsCount: number = 0;
  public flowersCount: number = 0;
  public editable: boolean = false;
  public buildingDetailsForm: FormGroup;
  public createdAt: string;
  public isLoadingComponent: boolean = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private buildingService: BuildingService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.building = data.building;

      const filteredFloors = this.building.floors.filter(floor => floor.status);

      this.floorsCount = filteredFloors.length;

      // flowers count
      filteredFloors.forEach(floor => {
        // todo - waiting for backend
        this.flowersCount += floor.flowers.filter(flower => flower.status).length;
      });

      this.buildingDetailsForm = new FormGroup({
        name: new FormControl(this.building.name, [Validators.required]),
        location: new FormControl(this.building.location, [Validators.required]),
      });
    });
  }

  toggleEditable() {
    this.editable = !this.editable;
  }

  save() {
    if (this.buildingDetailsForm.invalid) {
      return;
    }
    this.isLoadingComponent = true;
    this.buildingService.updateBuilding(this.building.id, this.buildingDetailsForm.value).subscribe((response) => {
      this.building.name = response.name;
      this.building.location = response.location;
      this.isLoadingComponent = false;
      this.breadcrumbService.lastPart = this.building.name;
    });
  }
}
