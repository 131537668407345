import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Flower } from '../../../../models/flower';
import { TaskService } from '../../../../services/task.service';
import { faTrash, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/operators';
import { DefaultTaskService } from '../../../../services/default_task.service';
import { DefaultTask } from '../../../../models/defaultTask';
import { ValidateOnlyNumbers } from '../../../../validators/only-numbers.validator';
import { ValidatePercentage } from '../../../../validators/percentage.validator';


@Component({
  selector: 'app-create-edit-point-in-floor-modal',
  templateUrl: './create-edit-point-in-floor-modal.component.html',
  styleUrls: ['./create-edit-point-in-floor-modal.component.scss']
})
export class CreateEditPointInFloorModalComponent  implements OnInit{
  @Input() flower: Flower;
  @Output() addedPointChange = new EventEmitter<any>();
  @Output() savedPointChange = new EventEmitter<any>();
  @Output() removePointChange = new EventEmitter<any>();

  public faTimes = faTimes;
  public floorPointCreateForm: FormGroup;
  public faTrash = faTrash;
  public faCircleNotch = faCircleNotch;
  public defaultTasks: DefaultTask[] = [];

  public loadingTasks: boolean = false;
  public loadingDefaultTasks: boolean = false;
  public removingTasks: number[] = [];

  public daysOfWeek = [
    {
      name: 'WEEK_DAYS.MONDAY.FULL',
      control: new FormControl(null, [Validators.required]),
      control_other: new FormControl(null, [Validators.required]),
      tasks: [],
      isLoading: false
    },
    {
      name: 'WEEK_DAYS.TUESDAY.FULL',
      control: new FormControl(null, [Validators.required]),
      control_other: new FormControl(null, [Validators.required]),
      tasks: [],
      isLoading: false
    },
    {
      name: 'WEEK_DAYS.WEDNESDAY.FULL',
      control: new FormControl(null, [Validators.required]),
      control_other: new FormControl(null, [Validators.required]),
      tasks: [],
      isLoading: false
    },
    {
      name: 'WEEK_DAYS.THURSDAY.FULL',
      control: new FormControl(null, [Validators.required]),
      control_other: new FormControl(null, [Validators.required]),
      tasks: [],
      isLoading: false
    },
    {
      name: 'WEEK_DAYS.FRIDAY.FULL',
      control: new FormControl(null, [Validators.required]),
      control_other: new FormControl(null, [Validators.required]),
      tasks: [],
      isLoading: false
    },
    {
      name: 'WEEK_DAYS.SATURDAY.FULL',
      control: new FormControl(null, [Validators.required]),
      control_other: new FormControl(null, [Validators.required]),
      tasks: [],
      isLoading: false
    },
    {
      name: 'WEEK_DAYS.SUNDAY.FULL',
      control: new FormControl(null, [Validators.required]),
      control_other: new FormControl(null, [Validators.required]),
      tasks: [],
      isLoading: false
    },
  ];

  constructor(
    public modal: NgbActiveModal,
    private taskService: TaskService,
    private defaultTaskService: DefaultTaskService,
    public cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {

    this.floorPointCreateForm = new FormGroup({
      title: new FormControl(this.flower ? this.flower.title : '', [Validators.required]),

      minTemperature: new FormControl(this.flower ? this.flower.minTemperature : '', [Validators.required, ValidateOnlyNumbers]),
      maxTemperature: new FormControl(this.flower ? this.flower.maxTemperature : '', [Validators.required, ValidateOnlyNumbers]),
      minMoisture: new FormControl(this.flower ? this.flower.minMoisture : '', [Validators.required, ValidatePercentage]),
      maxMoisture: new FormControl(this.flower ? this.flower.maxMoisture : '', [Validators.required, ValidatePercentage]),
    });
    if (this.flower) {
      this.loadingTasks = true;
      this.taskService
        .getTasks({
          'flower.id': this.flower.id,
          itemsPerPage: 999999
        })
        .pipe(finalize(() => this.loadingTasks = false))
        .subscribe(response => {
          const tasks = response.result['hydra:member'].filter(({ status }) => status);
          tasks.forEach(task => {
            this.daysOfWeek[task.day - 1].tasks.push(task);
          });
        });
    }

    this.loadingDefaultTasks = true;
    this.defaultTaskService.getDefaultTasks().subscribe((response) => {
      this.defaultTasks = response;
      this.daysOfWeek.map((day) => {
        return day.control.setValue(this.defaultTasks.length > 0 ? this.defaultTasks[0].description : '0');
      });
      this.loadingDefaultTasks = false;
    });
  }

  createTask(index): void {
    if (this.daysOfWeek[index].control.invalid) {
      return this.daysOfWeek[index].control.markAsTouched();
    }

    this.daysOfWeek[index].isLoading = true;

    this.taskService.createTask({
      flower: this.flower.id,
      description:
        this.daysOfWeek[index].control.value === '0' ?
          this.daysOfWeek[index].control_other.value :
          this.daysOfWeek[index].control.value,
      status: 1,
      day: index + 1,
    }).subscribe(({ id, description, day }) => {
      this.daysOfWeek[index].tasks.push({ id, description, day, flower: this.flower.id });
      this.cdr.detectChanges();
      // this.daysOfWeek[index].control.setValue(this.defaultTasks.length > 0 ? this.defaultTasks[0].description : '0');
      this.daysOfWeek[index].control_other.reset();
      this.daysOfWeek[index].isLoading = false;
    });
  }

  removeTask(taskId, day): void {
    if (this.removingTasks.includes(taskId)) {
      return;
    }
    this.removingTasks.push(taskId);
    this.taskService.removeTask(taskId).subscribe(() => {
      let index = day.tasks.findIndex(({ id }) => id === taskId);

      if (index > -1) {
        day.tasks.splice(index, 1);
        this.cdr.detectChanges();
      }

      index = this.removingTasks.findIndex((id) => id === taskId);

      if (index > -1) {
        this.removingTasks.splice(index, 1);
      }
    });
  }

  savePoint(flower: Flower): void {
    if (flower) {
      this.savedPointChange.emit({
        flower,
        form: this.floorPointCreateForm
      });
    } else {
      this.addedPointChange.emit({
        form: this.floorPointCreateForm
      });
    }
    this.modal.dismiss();
  }

  removePoint(flower: Flower): void {
    this.removePointChange.emit({
      flower
    });
    this.modal.dismiss();
  }
}
