<div class="container">
  <app-breadcrumb [backButton]="true"></app-breadcrumb>
  <div class="row mt-60">
    <div class="col-12 col-md-4">
      <!-- General information component -->
      <app-users-general-information></app-users-general-information>
    </div>
    <div class="col-12 col-md-8">
      <!-- work schedule component -->
      <app-users-work-schedule></app-users-work-schedule>
    </div>
  </div>
</div>
