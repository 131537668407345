import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '../../../ui-components/input/input.module';
import { ButtonModule } from '../../../ui-components/button/button.module';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';
import { UsersGeneralInformationComponent } from './users-general-information.component';
import { CheckboxModule } from '../../../ui-components/checkbox/checkbox.module';


@NgModule({
  declarations: [UsersGeneralInformationComponent],
  exports: [UsersGeneralInformationComponent],
  providers: [DatePipe],
  imports: [
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    InputModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    LoadingSmallModule,
    CheckboxModule
  ]
})
export class UsersGeneralInformationModule { }
