import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FloorService } from '../services/floor.service';
import { Floor } from '../models/floor';

@Injectable({
  providedIn: 'root'
})
export class FloorDetailedResolver implements Resolve<Floor[]> {

  constructor(
    private floorService: FloorService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Floor[]> {
    return this.floorService.getFloor(route.paramMap.get('floor_id'));
  }

}
