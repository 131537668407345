<div class="row">
  <div class="col-12">
    <div class="options-bar position-relative">
      <div>
        <app-loading-small
          *ngIf="isLoadingComponent || isGeneratingReport || isLoadingDataToPdf"
          position="absolute"
          top="-40px"
          right="0">
        </app-loading-small>
      </div>

      <div class="filters">
        <div>
          <div class="selects">
            <form [formGroup]="searchBuildingForm" class="select-buildings-form">
              <div class="select-buildings">
                <select class="form-control" formControlName="searchBuilding" [attr.disabled]="isLoadingBuildings ? '' : null">
                  <option [selected]=true [disabled]="true" value="">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.SELECT_BUILDINGS_PLACEHOLDER' | translate }}</option>
                  <option value="all">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.SELECT_BUILDINGS_ALL_BUILDINGS' | translate }}</option>
                  <option *ngFor="let building of buildings" value="{{building.id}}">
                    {{building.name}}
                  </option>
                </select>
              </div>
            </form>

            <form [formGroup]="searchUserForm" class="select-users-form">
              <div class="select-users">
                <select class="form-control" formControlName="searchUser" [attr.disabled]="isLoadingUsers ? '' : null">
                  <option [selected]=true [disabled]="true" value="">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.SELECT_USERS_PLACEHOLDER' | translate }}</option>
                  <option value="all">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.SELECT_BUILDINGS_ALL_USERS' | translate }}</option>
                  <option *ngFor="let user of users" value="{{user.id}}">
                    {{user.userFullName}}
                  </option>
                </select>
              </div>
            </form>

            <form [formGroup]="searchStatusForm" class="select-status-form">
              <div class="select-status">
                <select class="form-control" formControlName="searchStatus">
                  <option [selected]=true [disabled]="true" value="">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.SELECT_STATUS_PLACEHOLDER' | translate }}</option>
                  <option value="all">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.SELECT_ALL_STATUSES' | translate }}</option>
                  <option *ngFor="let status of statuses" value="{{ status.id }}">
                    {{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.STATUSES_NUMBER.' + status.id | translate }}
                  </option>
                </select>
              </div>
            </form>
          </div>

          <div class="d-flex">
            <app-range-datepicker [resetDate]="resetDate" (pickerDateChange)="pickerDateChange($event)"></app-range-datepicker>
            <div ngbDropdown class="d-inline-block">
              <button
                class="btn btn-outline-primary ml-10"
                id="dropdownBasic1"
                ngbDropdownToggle
                ngbTooltip="{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.RESET_FILTERS' | translate }}">
                <fa-icon [icon]="faEraser"></fa-icon>
              </button>
              <div ngbDropdownMenu>
                <button ngbDropdownItem (click)="clearAllFilters()">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.RESET_FILTERS_ITEMS.ALL' | translate }}</button>
                <button ngbDropdownItem (click)="clearBuildingFilters()">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.RESET_FILTERS_ITEMS.BUILDING' | translate }}</button>
                <button ngbDropdownItem (click)="clearUserFilters()">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.RESET_FILTERS_ITEMS.USER' | translate }}</button>
                <button ngbDropdownItem (click)="clearStatusFilters()">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.RESET_FILTERS_ITEMS.STATUS' | translate }}</button>
                <button ngbDropdownItem (click)="clearDateFilters()">{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.RESET_FILTERS_ITEMS.DATE' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <app-button
            (click)="generatePdf()"
            [disabled]="isGeneratingReport || isLoadingComponent || !dataIsReadyToGenerate"
            [title]="'COMPONENTS.DASHBOARD.REPORTS_TABLE.GENERATE_REPORTS' | translate"
            [class]="'btn btn-sm btn-primary font-weight-bold generate-report-btn'">
          </app-button>

          <span class="no-reports-info" *ngIf="itemsInPdf <= 0 && !isLoadingDataToPdf">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
            {{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.NO_REPORT_TO_PDF' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading-content [loading]="isLoadingComponent">
  <div class="row" *ngIf="dayTasks?.length > 0; else noTasks">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-borderless table-calla">
          <thead>
          <tr #sortableTableRow>
            <th>
              <input (change)="checkUncheckedDayTasks()" (click)="uncheckAndCheckAll()" [checked]="checkState" type="checkbox"
                     class="checkbox checkbox--opacity">
            </th>
            <th>
              {{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.TABLE_NAME' | translate }}
            </th>
            <th>
              {{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.TABLE_FLOWER' | translate }}
            </th>
            <th>
              {{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.TABLE_BUILDING' | translate }}
            </th>
            <th>
              {{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.TABLE_EXECUTOR' | translate }}
            </th>
            <th sortable (click)="sort('updatedAt', directionSortValue, $event)">
              {{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.TABLE_UPDATED_AT' | translate }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let dayTask of dayTasks">
            <td scope="row p-relative">
              <input (change)="checkUncheckedDayTasks()" (click)="uncheckAndCheckOne(dayTask.id)" [checked]="dayTask.checked"
                     type="checkbox" class="checkbox">
            </td>
            <td>
              <fa-icon
                *ngIf="dayTask.status === 2"
                placement="top"
                ngbTooltip="{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.STATUSES.DONE' | translate }}"
                class="task-icon text-primary"
                [icon]="faCheckCircle">
              </fa-icon>
              <fa-icon
                *ngIf="dayTask.status === 0"
                placement="top"
                ngbTooltip="{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.STATUSES.DELETED' | translate }}"
                class="task-icon text-danger"
                [icon]="faTimesCircle">
              </fa-icon>
              <fa-icon
                *ngIf="dayTask.status === 3"
                (click)="showProblemModal(dayTask?.problems[0])"
                placement="top"
                ngbTooltip="{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.STATUSES.HAVE_PROBLEM' | translate }}"
                class="task-icon text-warning cursor-pointer user-select-none"
                [icon]="faExclamationCircle">
              </fa-icon>
              <fa-icon
                *ngIf="dayTask.status === 1"
                placement="top"
                ngbTooltip="{{ 'COMPONENTS.DASHBOARD.REPORTS_TABLE.STATUSES.NOT_DONE' | translate }}"
                class="task-icon text-muted"
                [icon]="faCircle">
              </fa-icon>
              {{ dayTask.task.description }}
            </td>
            <td>{{ dayTask.flower.title }}</td>
            <td>{{ dayTask.flower.floor.building.name }}</td>
            <td>{{ dayTask?.updatedBy ? dayTask?.updatedBy.userFullName : '--' }}</td>
<!--            <td>{{ dayTask.problems?.length > 0 ? dayTask.problems.createdAt : dayTask.updatedAt | date:"dd.MM.yyyy HH:mm" }}</td>-->
            <td>{{ dayTask.updatedAt && dayTask.status != 1 && dayTask.status != 0 ? (dayTask?.updatedAt | date:"dd.MM.yyyy HH:mm") : '--' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <app-pagination
          (currentPageChange)="changePage($event)"
          [(currentPage)]="this.currentPage"
          [lastPage]="this.lastPage"
          [nextPage]="this.nextPage"
          [previousPage]="this.previousPage"
          [totalPages]="this.totalPages">
        </app-pagination>
      </div>
    </div>
  </div>
</app-loading-content>

<ng-template #noTasks>
  <app-alert
    [title]="'COMPONENTS.DASHBOARD.REPORTS_TABLE.NO_TASKS' | translate"
    [class]="'alert-warning mt-20 mb-20'">
  </app-alert>
</ng-template>
