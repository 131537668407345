import { Component, OnInit } from '@angular/core';
import { faAngleRight, faCog, faEdit } from '@fortawesome/free-solid-svg-icons';
import { EmployeeService } from '../../../../services/employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../../models/user';
import { mergeMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditUserWorkScheduleModalComponent } from '../../../ui-components/modals/edit-user-work-schedule-modal/edit-user-work-schedule-modal.component';

@Component({
  selector: 'app-users-work-schedule',
  templateUrl: './users-work-schedule.component.html',
  styleUrls: ['./users-work-schedule.component.scss']
})
export class UsersWorkScheduleComponent implements OnInit {
  public faAngleRight = faAngleRight;
  public faCog = faCog;
  public faEdit = faEdit;
  public userBuildings;
  public userId: User;
  public isLoadingComponent: boolean = false;
  public editable: boolean = false;

  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.isLoadingComponent = true;
    this.route.data.pipe(mergeMap((data) => {
      this.userId = data.user.id;

      return this.employeeService.getUserBuildings({
        'worker.id': this.userId,
        itemsPerPage: 999,
        status: 1
      });
    })).subscribe((response) => {
      this.userBuildings = response.result['hydra:member'];
      this.userBuildings = this.userBuildings.filter(build => build.status);
      this.isLoadingComponent = false;
    });
  }

  toggleEditable() {
    this.editable = !this.editable;
  }

  listItemAction(userBuilding) {
    if (this.editable) {
      const editUserWorkScheduleModal = this.modalService.open(EditUserWorkScheduleModalComponent, {
        backdropClass: 'light-blue-backdrop',
        centered: true
      });

      editUserWorkScheduleModal.componentInstance.userBuilding = userBuilding;
    } else {
      this.router.navigate([ '/buildings/', userBuilding.building.id]);
    }
  }

}
