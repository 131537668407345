import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private static readonly API_URL = environment.apiUrl;

  public url(path?: string): string {
    return ApiService.API_URL + (path ? `/${path}` : '');
  }
}

