import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Floor } from '../../../../../models/floor';
import { finalize, tap } from 'rxjs/operators';
import { FlowerService } from '../../../../../services/flower.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEditPointInFloorModalComponent } from '../../../../ui-components/modals/create-edit-point-in-floor-modal/create-edit-point-in-floor-modal.component';
import { Flower } from '../../../../../models/flower';

@Component({
  selector: 'app-buildings-floors-list-of-points',
  templateUrl: './buildings-floors-list-of-points.component.html',
  styleUrls: ['./buildings-floors-list-of-points.component.scss']
})
export class BuildingsFloorsListOfPointsComponent implements OnInit {
  public floor: Floor;
  public isLoadingComponent: boolean = false;
  @Input() flowers: Flower[];
  @Input() isLoadingPageComponent: boolean;
  @Output() addedPointChange = new EventEmitter<any>();
  @Output() removedPointChange = new EventEmitter<any>();
  @Output() updatedPointChange = new EventEmitter<any>();

  constructor(
    public flowerService: FlowerService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.floor = data.floor;
    });

    this.flowerService.draggingFlower.subscribe();
  }

  createEditPointModal(flower?: Flower) {
    const createPointModal = this.modalService.open(CreateEditPointInFloorModalComponent, {
      backdropClass: 'light-blue-backdrop',
      centered: true
    });

    if (flower) {
      createPointModal.componentInstance.flower = flower;
    }

    createPointModal.componentInstance.floorId = this.floor['id'];

    // Save point/flower
    createPointModal.componentInstance.savedPointChange.subscribe((response) => {
      this.isLoadingComponent = true;
      const form = response.form;
      const point = response.flower;

      const pointFullData = {
        locationX: point.locationX,
        locationY: point.locationY,
        locationR: 0,
        title: form.value.title,
        minTemperature: parseFloat(form.value.minTemperature),
        maxTemperature: parseFloat(form.value.maxTemperature),
        minMoisture: parseFloat(form.value.minMoisture),
        maxMoisture: parseFloat(form.value.maxMoisture)
      };

      this.flowerService.updateFlower(point.id, pointFullData).pipe(finalize(() => {
        const foundPoint = this.flowers.find(item => item.id === response.flower.id);
        if (foundPoint) {
          foundPoint.title = form.value.title;
          foundPoint.minTemperature = form.value.minTemperature;
          foundPoint.maxTemperature = form.value.maxTemperature;
          foundPoint.minMoisture = form.value.minMoisture;
          foundPoint.maxMoisture = form.value.maxMoisture;
        }
      })).subscribe(() => {
        this.updatedPointChange.emit({
          response
        });
        this.isLoadingComponent = false;
      });
    });

    // Add point/flower
    createPointModal.componentInstance.addedPointChange.subscribe((receivedPoint) => {
      this.isLoadingComponent = true;

      const preparedPoint: Flower = {
        title: receivedPoint.form.value.title,
        locationX: 0,
        locationY: 0,
        locationR: 0,
        status: 1,
        floor: this.floor.id,
        minTemperature: parseFloat(receivedPoint.form.value.minTemperature),
        maxTemperature: parseFloat(receivedPoint.form.value.maxTemperature),
        minMoisture: parseFloat(receivedPoint.form.value.minMoisture),
        maxMoisture: parseFloat(receivedPoint.form.value.maxMoisture)
      };

      this.flowerService.createFlower(preparedPoint).subscribe((response) => {
        preparedPoint['id'] = response.id;
        this.flowers.push(preparedPoint);
        this.addedPointChange.emit({
          response
        });
        this.isLoadingComponent = false;
      });
    });

    // Remove point/flower
    createPointModal.componentInstance.removePointChange.subscribe((response) => {
      this.isLoadingComponent = true;
      this.flowerService.removeFlower(response.flower.id).pipe(finalize(() => {
        this.removePointFromArray(response);
      })).subscribe(() => {
        this.removedPointChange.emit({
          response
        });
        this.isLoadingComponent = false;
      });
    });
  }

  private removePointFromArray(receivedPoint) {
    const removeIndex = this.flowers.map(item => item.id).indexOf(receivedPoint.flower.id);
    this.flowers.splice(removeIndex, 1);
  }

  // Hover point
  onHoverStart(flower: Flower): void {
    this.flowerService.startHover(flower.id);
  }

  onHoverEnd(): void {
    this.flowerService.endHover();
  }
}
