import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-not-fount',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss'],
})
export class NotFoundPageComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
    }
  }
}
