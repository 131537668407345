import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  public canActivate(): boolean {
    return this.can();
  }

  public canActivateChild(): boolean {
    return this.can();
  }

  private can(): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['login']);

      return false;
    }

    return true;
  }
}
