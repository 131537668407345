<div class="card card-details-editable">
  <div class="card-body">
    <div class="card-title">{{ 'COMPONENTS.DASHBOARD.SETTINGS_ITEMS_DEFAULT_TASKS.TITLE' | translate }}</div>
    <div class="card-content">
      <ul class="items-list" *ngIf="defaultTasks?.length > 0; else noDefaultTasks">
        <li *ngFor="let defaultTask of defaultTasks">
          <div class="li-content">
            <div class="title d-flex justify-content-between align-items-center">
              <span>{{ defaultTask.description }}</span>
              <span (click)="createEditDefaultTaskModal(defaultTask)" class="edit-default-task">{{ 'COMPONENTS.DASHBOARD.SETTINGS_ITEMS_DEFAULT_TASKS.EDIT' | translate }}</span>
            </div>
          </div>
          <fa-icon (click)="removeDefaultTask(defaultTask.id)" *ngIf="editable" class="remove-icon"
                   [icon]="faTimesCircle"></fa-icon>
        </li>
      </ul>
      <ng-template #noDefaultTasks>
        <app-alert
          [title]="'COMPONENTS.DASHBOARD.SETTINGS_ITEMS_DEFAULT_TASKS.NO_DEFAULT_TASKS' | translate"
          [class]="'alert-warning mt-20 mb-20'">
        </app-alert>
      </ng-template>

      <app-button
        (click)="createEditDefaultTaskModal()"
        [title]="'COMPONENTS.DASHBOARD.SETTINGS_ITEMS_DEFAULT_TASKS.ADD_DEFAULT_TASK' | translate"
        [class]="'btn btn-primary font-weight-bold btn-sm padding-sm'"
        icon="add">
      </app-button>
    </div>
    <fa-icon class="edit-cog" [class.edit-cog--active]="editable" [icon]="faCog" (click)="toggleEditable()"></fa-icon>
    <app-loading-small
      *ngIf="isLoadingComponent"
      position="absolute"
      bottom="35px"
      right="30px">
    </app-loading-small>
  </div>
</div>
