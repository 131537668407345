import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { EmployeeService } from '../../../../services/employee.service';
enum Days {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

@Component({
  selector: 'app-edit-user-work-schedule-modal',
  templateUrl: './edit-user-work-schedule-modal.component.html',
  styleUrls: ['./edit-user-work-schedule-modal.component.scss']
})
export class EditUserWorkScheduleModalComponent implements OnInit {
  public faTimes = faTimes;
  public assignedWorkDaysForm: FormGroup;
  public isLoadingComponent: boolean = false;
  public savingData: boolean = false;
  public days = [];
  @Input() userBuilding: any;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
  ) { }

  ngOnInit(): void {
    this.isLoadingComponent = true;

    this.assignedWorkDaysForm = new FormGroup({
      MONDAY: new FormControl(false),
      TUESDAY: new FormControl(false),
      WEDNESDAY: new FormControl(false),
      THURSDAY: new FormControl(false),
      FRIDAY: new FormControl(false),
      SATURDAY: new FormControl(false),
      SUNDAY: new FormControl(false),
    });

    this.employeeService.getUserBuilding(this.userBuilding.id).subscribe(response => {
      this.days = response.days;

      this.days.forEach(day => {
        this.assignedWorkDaysForm.controls[Days[day]].setValue(true);
      });

      this.isLoadingComponent = false;
    });
  }

  save(): void {
    this.savingData = true;
    const daysToSave = [];

    Object.keys(this.assignedWorkDaysForm.controls).forEach(key => {
      const value = this.assignedWorkDaysForm.get(key).value;
      if (value) {
        daysToSave.push(Days[key]);
      }
    });

    const body = {
      worker: this.userBuilding.worker.id,
      building: this.userBuilding.building.id,
      days: daysToSave
    };

    this.employeeService.updateEmployeeBuilding(this.userBuilding.id, body).subscribe(response => {
      this.savingData = false;

      this.modal.dismiss();
    });
  }
}
