import { NgModule } from '@angular/core';
import { DashboardPageComponent } from './dashboard.page';
import { HeaderModule } from '../../components/dashboard/header/header.module';
import { RouterModule } from '@angular/router';
import { BuildingsShowPageModule } from './buildings/buildings-show/buildings-show.module';
import { BuildingsIndexPageModule } from './buildings/buildings-index/buildings-index.module';
import { BuildingsCreatePageModule } from './buildings/buildings-create/buildings-create.module';
import { UsersIndexPageModule } from './users/users-index/users-index.module';
import { UsersCreatePageModule } from './users/users-create/users-create.module';
import { UsersShowPageModule } from './users/users-show/users-show.module';
import { BuildingsFloorsShowPageModule } from './buildings/buildings-floors/buildings-floors-show/buildings-floors-show.module';
import { ButtonModule } from '../../components/ui-components/button/button.module';
import { CommonModule } from '@angular/common';
import { WelcomePageModule } from './welcome/welcome.module';
import { ReportsPageModule } from './reports/reports.module';
import { SettingsPageModule } from './settings/settings.module';


@NgModule({
  declarations: [DashboardPageComponent],
  exports: [DashboardPageComponent],
  imports: [
    HeaderModule,
    RouterModule,

    // Users
    UsersIndexPageModule,
    UsersShowPageModule,
    UsersCreatePageModule,

    // Buildings
    BuildingsIndexPageModule,
    BuildingsShowPageModule,
    BuildingsCreatePageModule,

    // Reports
    ReportsPageModule,

    // Buildings - floors
    BuildingsFloorsShowPageModule,

    // Welcome
    WelcomePageModule,

    // Settings
    SettingsPageModule
  ]
})
export class DashboardModule {
}
