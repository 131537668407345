import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SettingsItemsDefaultTasksComponent } from './settings-items-default-tasks.component';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';
import { AlertModule } from '../../../ui-components/alert/alert.module';
import { ButtonModule } from '../../../ui-components/button/button.module';
import { CreateEditDefaultTaskModalModule } from '../../../ui-components/modals/create-edit-default-task-modal/create-edit-default-task-modal.module';


@NgModule({
  declarations: [SettingsItemsDefaultTasksComponent],
  exports: [SettingsItemsDefaultTasksComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    BrowserModule,
    NgbModule,
    FontAwesomeModule,
    LoadingSmallModule,
    AlertModule,
    ButtonModule,
    CreateEditDefaultTaskModalModule,
  ]
})
export class SettingsItemsDefaultTasksModule { }
