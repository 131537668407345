import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { Task } from '../models/task';
import { getFilterTaskParams } from './helpers/filterHelper';
import { getPaginatedResult } from './helpers/paginationHelper';

@Injectable({
  providedIn: 'root',
})
export class TaskService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService
  ) {}

  getTasks(data): Observable<any> {
    const params = getFilterTaskParams(data);
    return getPaginatedResult<Task[]>(this.apiService.url('tasks'), params, this.httpClient);
  }

  getTask(taskId: string): Observable<Task> {
    return this.httpClient.get<Task>(this.apiService.url('tasks/' + taskId));
  }

  createTask(data): Observable<Task> {
    return this.httpClient.post<Task>(this.apiService.url('tasks'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.TASK.NOTIFICATIONS.TASK_CREATED');
        })
      );
  }

  updateTask(taskId, data): Observable<Task> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');
    return this.httpClient.patch<Task>(this.apiService.url('tasks/' + taskId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.TASK.NOTIFICATIONS.TASK_UPDATED');
        })
      );
  }

  removeTask(taskId: number): Observable<Task> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');

    return this.httpClient.patch<Task>(this.apiService.url('tasks/' + taskId), { status: 0 }, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.TASK.NOTIFICATIONS.TASK_DELETED');
        })
      );
  }
}
