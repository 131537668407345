import { NgModule } from '@angular/core';
import { BuildingsCreatePageComponent } from './buildings-create.page';
import { BuildingsCreateModule } from '../../../../components/dashboard/buildings/buildings-create/buildings-create.module';
import { BreadcrumbModule } from '../../../../components/ui-components/breadcrumb/breadcrumb.module';


@NgModule({
  declarations: [BuildingsCreatePageComponent],
  exports: [BuildingsCreatePageComponent],
  imports: [
    BreadcrumbModule,
    BuildingsCreateModule
  ]
})
export class BuildingsCreatePageModule {
}
