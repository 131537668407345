import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from '../services/token.service';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from '../services/notifications.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private notificationsService: NotificationsService,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('login') || request.url.includes('reset_password')) {
      return next.handle(request);
    }

    return next.handle(request.clone({
      headers: request.headers.append(
        'Authorization',
        `Bearer ${this.tokenService.token}`,
      ),
    })).pipe(
      catchError((error) => {
        console.log(error);

        if (error?.status === 401) {
          this.notificationsService.warning('SERVICES.AUTH.NOTIFICATIONS.SESSION_EXPIRED');
          this.authService.logout(false);
        }

        return throwError(error);
      }),
    );
  }
}
