export class Config {
  /**
   * Pozycja ostrego rogu pinezki względem jej lewej krawędzi (px).
   */
  public static readonly MARKER_NEEDLE_X = 21;
  /**
   * Pozycja ostrego rogu pinezki względem jej górnej krawędzi (px).
   */
  public static readonly MARKER_NEEDLE_Y = 40;
  /**
   * Minimalna szerokość wgrywanego obrazka mapy piętra (px).
   */
  public static readonly UPLOADED_MAP_IMAGE_MIN_WIDTH = 768;
  /**
   * Minimalna wysokość wgrywanego obrazka mapy piętra (px).
   */
  public static readonly UPLOADED_MAP_IMAGE_MIN_HEIGHT = 768;
  /**
   * Maksymalna szerokość wgrywanego obrazka mapy piętra (px).
   */
  public static readonly UPLOADED_MAP_IMAGE_MAX_WIDTH = 4096;
  /**
   * Maksymalna wysokość wgrywanego obrazka mapy piętra (px).
   */
  public static readonly UPLOADED_MAP_IMAGE_MAX_HEIGHT = 4096;
  /**
   * Maksymalna wielkość pliku orazka mapy piętra (MB).
   */
  public static readonly UPLOADED_MAP_IMAGE_MAX_SIZE = 5;
  /**
   * Dopuszczone formaty pliku obrazka mapy piętra.
   */
  public static readonly UPLOADED_MAP_ALLOWED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  /**
   * Maksymalna szerokość skompresowanego obrazka mapy piętra (px).
   */
  public static readonly COMPRESSED_MAP_IMAGE_MAX_WIDTH = 2048;
  /**
   * Maksymalna wysokość skompresowanego obrazka mapy piętra (px).
   */
  public static readonly COMPRESSED_MAP_IMAGE_MAX_HEIGHT = 2048;
  /**
   * Domyślny czas wyświetlania powiadomienia (ms)
   */
  public static readonly NOTIFICATION_DEFAULT_TIMEOUT = 5000;
  /**
   * Status tasków wykonanych
   */
  public static readonly STATUS_TASKS_DONE = 2;
  /**
   * Status tasków niewykonanych
   */
  public static readonly STATUS_TASKS_NOT_DONE = 1;
  /**
   * Rola admina
   */
  public static readonly ROLE_ADMIN = 'ROLE_ADMIN';
  /**
   * Rola usera
   */
  public static readonly ROLE_USER = 'ROLE_USER';
}
