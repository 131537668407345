<nav class="navbar navbar-expand-lg navbar-light bg-white">
  <div class="container">
    <a routerLink="/welcome" class="navbar-brand">
      <img src="assets/images/calla2.png" class="img-fluid logo" alt="{{ 'COMPONENTS.DASHBOARD.HEADER.LOGO_ALT' | translate }}">
    </a>
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" aria-expanded="false">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink='/buildings' routerLinkActive="active">{{ 'COMPONENTS.DASHBOARD.HEADER.BUILDINGS' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/users' routerLinkActive="active">{{ 'COMPONENTS.DASHBOARD.HEADER.USERS' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/reports' routerLinkActive="active">{{ 'COMPONENTS.DASHBOARD.HEADER.REPORTS' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink='/settings'  routerLinkActive="active">{{ 'COMPONENTS.DASHBOARD.HEADER.SETTINGS' | translate }}</a>
        </li>
      </ul>
      <ul class="navbar-nav-right">
        <li class="nav-item">{{ loggedUser?.userFullName }} <span class="email">{{ loggedUser?.email }}</span></li>
        <li class="nav-item"><a (click)="logout()" class="nav-link nav-link--logout">{{ 'COMPONENTS.DASHBOARD.HEADER.LOGOUT' | translate }}</a></li>
      </ul>
    </div>
  </div>
</nav>
