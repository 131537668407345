<div class="card card-details-editable mt-5 mt-lg-0">
  <div class="card-body">
    <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_FLOORS_LIST_OF_POINTS.LIST_OF_POINTS' | translate }}</h5>
    <div class="card-content">
      <ul *ngIf="flowers.length > 0 else noFlowers">
        <li *ngFor="let flower of flowers;"
            [class.active]="flower.id === (flowerService.hoverFlower | async) || flower.id === (flowerService.draggingFlower | async)"
            (mouseenter)="onHoverStart(flower)"
            (mouseleave)="onHoverEnd()">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ flower.title }}</span>
            <span (click)="createEditPointModal(flower)" class="edit-point">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_FLOORS_LIST_OF_POINTS.EDIT' | translate }}</span>
          </div>
        </li>
      </ul>
      <ng-template #noFlowers>
        <app-alert
          [title]="'COMPONENTS.DASHBOARD.BUILDINGS_FLOORS_LIST_OF_POINTS.NO_POINTS' | translate"
          [class]="'alert-warning mt-20 mb-20'">
        </app-alert>
      </ng-template>
      <app-button
        (click)="createEditPointModal()"
        [title]="'COMPONENTS.DASHBOARD.BUILDINGS_FLOORS_LIST_OF_POINTS.ADD_POINT' | translate"
        [class]="'btn btn-primary font-weight-bold btn-sm padding-sm'"
        icon="add">
      </app-button>
    </div>
    <app-loading-small
      *ngIf="isLoadingPageComponent || isLoadingComponent"
      position="absolute"
      bottom="35px"
      right="30px">
    </app-loading-small>
  </div>
</div>
