<div class="modal-body">
  <button
    (click)="modal.dismiss('Cancel click')"
    type="button"
    class="close-modal"
    aria-describedby="modal-title">
    <fa-icon class="close-icon" [icon]="faTimes"></fa-icon>
  </button>
  <h3 class="font-weight-bold text-center">{{ defaultTask ? ('COMPONENTS.DASHBOARD.CREATE_DEFAULT_TASK_MODAL.TITLE_EDIT' | translate) : ('COMPONENTS.DASHBOARD.CREATE_DEFAULT_TASK_MODAL.TITLE_ADD' | translate) }}</h3>

  <form [formGroup]="defaultTaskCreateForm">
    <app-input
      [class]="'form-control'"
      [label]="'COMPONENTS.DASHBOARD.CREATE_DEFAULT_TASK_MODAL.TASK_NAME_LABEL' | translate"
      [placeholder]="'COMPONENTS.DASHBOARD.CREATE_DEFAULT_TASK_MODAL.TASK_NAME_PLACEHOLDER' | translate"
      formControlName="description"
      name="description"
      type="text">
    </app-input>
  </form>

  <div class="d-flex justify-content-end mt-20">
    <app-button (click)="saveDefaultTask()"
                [disabled]="defaultTaskCreateForm.invalid"
                [title]="defaultTask ? ('COMPONENTS.DASHBOARD.CREATE_DEFAULT_TASK_MODAL.SAVE_DEFAULT_TASK' | translate) : 'COMPONENTS.DASHBOARD.CREATE_DEFAULT_TASK_MODAL.ADD_DEFAULT_TASK' | translate"
                [class]="'btn btn-primary font-weight-bold btn-sm'">
    </app-button>
  </div>
</div>
