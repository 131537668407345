<div class="container pb-60">
  <app-breadcrumb [backButton]="true"></app-breadcrumb>
  <div class="row mt-60">
    <div class="col-12 col-lg-8">
      <app-buildings-floors-map
        [addedFlower]="addedFlower"
        [removedFlower]="removedFlower"
        [updatedFlower]="updatedFlower"
        [flowers]="flowers"
        [isLoadingPageComponent]="isLoadingPageComponent"
      ></app-buildings-floors-map>
    </div>
    <div class="col-12 col-lg-4">
      <app-buildings-floors-list-of-points
        (addedPointChange)="addedPoint($event)"
        (removedPointChange)="removedPoint($event)"
        (updatedPointChange)="updatedPoint($event)"
        [flowers]="flowers"
        [isLoadingPageComponent]="isLoadingPageComponent"
      ></app-buildings-floors-list-of-points>
      <app-buildings-floors-general-information></app-buildings-floors-general-information>
    </div>
  </div>
</div>
