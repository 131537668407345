import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { LoginRequest, LoginResponse } from '../interfaces/login';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { ResetPasswordRequest, ResetPasswordResponse } from '../interfaces/reset-password';
import { ChangePasswordRequest, ChangePasswordResponse } from '../interfaces/change-password';

@Injectable({
  providedIn: 'root',
})
export class AuthService{
  private userSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public user$: Observable<User> = this.userSubject$.asObservable();

  public get user(): User {
    return this.userSubject$.value;
  }

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private tokenService: TokenService,
    private notificationsService: NotificationsService,
  ) {

  }

  public login$(data: LoginRequest): Observable<LoginResponse> {
    return this.httpClient
      .post<LoginResponse>(this.apiService.url('users/login'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error(
            [400, 401, 404].includes(error.status)
              ? 'SERVICES.AUTH.NOTIFICATIONS.BAD_CREDENTIALS'
              : 'SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR',
          );

          return throwError(error);
        }),
        tap(({ user, token }) => {
          this.setUser(user);
          this.tokenService.token = token;
          this.notificationsService.success('SERVICES.AUTH.NOTIFICATIONS.LOGIN_SUCCESS');
        })
      );
  }

  public isAuthenticated(): boolean {
    return this.tokenService.isActive();
  }

  public logout(showMessage: boolean = true): void {
    this.userSubject$.next(null);
    this.tokenService.remove();
    this.router.navigate(['login']).then(() =>
      showMessage &&
      this.notificationsService.success('SERVICES.AUTH.NOTIFICATIONS.LOGOUT_SUCCESS')
    );
  }

  public rememberPassword$(request: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    return this.httpClient.post<ResetPasswordResponse>(this.apiService.url('users/reset_code'), request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.AUTH.NOTIFICATIONS.SEND_RESET_PASSWORD_EMAIL');
        })
      );
  }

  public changePassword(request: ChangePasswordRequest): Observable<ChangePasswordResponse> {
    return this.httpClient.post<ChangePasswordResponse>(this.apiService.url('users/reset_password'), request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error(
            [403].includes(error.status)
              ? 'SERVICES.AUTH.NOTIFICATIONS.EMAIL_OR_CODE_IS_INVALID'
              : 'SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR',
          );
          return throwError(error);
        }),
        tap(() => {
          this.router.navigate(['login']).then(() =>
            this.notificationsService.success('SERVICES.AUTH.NOTIFICATIONS.PASSWORD_HAS_BEEN_CHANGES')
          );
        })
      );
  }

  getUser$(): Observable<User> {
    return this.httpClient
      .get<User>(this.apiService.url('users/me'))
      .pipe(tap((user: User) => this.setUser(user)));
  }

  setUser(user: User): void {
    this.userSubject$.next(user);
  }
}
