<div class="container position-relative">
  <app-breadcrumb [backButton]="false"></app-breadcrumb>
  <div class="row mt-60">
    <div class="col-12 col-md-3 mb-30 mb-md-0">
      <div class="list-group">
        <a [routerLink]="[{ outlets: { sidebarSettings: ['default-tasks'] } }]" class="list-group-item list-group-item-action active" aria-current="true">
          {{ 'COMPONENTS.DASHBOARD.SETTINGS.DEFAULT_TASKS' | translate }}
        </a>
      </div>
    </div>
    <div class="col-12 col-md-9">
      <router-outlet name="sidebarSettings"></router-outlet>
    </div>
  </div>
</div>
