import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Building } from '../models/building';
import { BuildingService } from '../services/building.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingDetailedResolver implements Resolve<Building> {

  constructor(
    private buildingService: BuildingService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Building> {
    return this.buildingService.getBuilding(route.paramMap.get('building_id'));
  }

}
