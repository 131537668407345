<div class="modal-body">
  <button
    (click)="modal.dismiss('Cancel click')"
    type="button"
    class="close-modal"
    aria-describedby="modal-title">
    <fa-icon class="close-icon" [icon]="faTimes"></fa-icon>
  </button>
  <h3 class="font-weight-bold text-center">{{ 'COMPONENTS.DASHBOARD.EDIT_USER_WORK_SCHEDULE_MODAL.TITLE' | translate }}</h3>

  <div *ngIf="days">
    <app-loading-content [loading]="isLoadingComponent">
      <form [formGroup]="assignedWorkDaysForm">
        <div class="checkboxes-days">
          <div class="form-group" *ngFor="let day of ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']">
            <input [formControlName]="day" type="checkbox" [id]="day.toLowerCase()">
            <label [for]="day.toLowerCase()">{{ ('WEEK_DAYS.' + day + '.FULL' ) | translate }}</label>
          </div>
        </div>
      </form>

      <div class="d-flex justify-content-end mt-20">
        <app-button (click)="save()"
                    [disabled]="assignedWorkDaysForm.invalid"
                    [title]="'COMPONENTS.DASHBOARD.EDIT_USER_WORK_SCHEDULE_MODAL.SAVE' | translate"
                    [class]="'btn btn-primary font-weight-bold btn-sm'">
        </app-button>
      </div>
    </app-loading-content>
  </div>


  <app-loading-small
    *ngIf="savingData"
    position="absolute"
    bottom="30px"
    left="30px">
  </app-loading-small>
</div>
