import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isMenuCollapsed = true;
  public loggedUser = null;
  private subscription$: Subscription;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.subscription$ = this.authService.user$.subscribe((user) => {
      this.loggedUser = user;
    });
  }

  ngOnDestroy(): void {
    this.subscription$?.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }
}
