<div class="card card-details-editable">
  <div class="card-body">
    <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_FLOORS_MAP.MAP' | translate }}</h5>

    <div class="card-content">
      <div #fullScreenOverlay class="fullscreen-overlay" [class.fullscreen]="fullScreenMode">
        <div #map class="field" *ngIf="flowers && floorImage else noMap">
          <img *ngIf="floorImage" [src]="floorImage" class="img-map" #imgMap>
          <div
            *ngFor="let flower of flowers;"
            class="marker"
            cdkDragBoundary=".field"
            cdkDrag
            (cdkDragStarted)="onDragStart(flower)"
            (cdkDragEnded)="onDragEnd($event, flower)"
            (mouseenter)="onHoverStart(flower)"
            (mouseleave)="onHoverEnd()"
            [cdkDragFreeDragPosition]="{ x: flower.locationX || 0, y: flower.locationY || 0 }"
            [class.active]="flower.id === (flowerService.hoverFlower | async)">
            <fa-icon
              [icon]="faMapMarkerAlt"
              class="arrow-icon">
            </fa-icon>
            <h5>{{ flower.title}}</h5>
          </div>
        </div>
      </div>

      <div class="buttons">
        <app-button
          (click)="openUploadMapModal()"
          [title]="floor.photo ? ('COMPONENTS.DASHBOARD.BUILDINGS_FLOORS_MAP.UPDATE_MAP' | translate) : ('COMPONENTS.DASHBOARD.BUILDINGS_FLOORS_MAP.UPLOAD_MAP' | translate)"
          class="btn btn-sm font-weight-bold btn-primary"
          icon="upload">
        </app-button>
        <app-button
          (click)="openFullScreenMode()"
          title="Przejdź w tryb pełnoekranowy"
          class="btn btn-sm font-weight-bold btn-primary" icon="expand"></app-button>
      </div>
    </div>

    <ng-template #noMap>
      <app-alert
        [title]="'COMPONENTS.DASHBOARD.BUILDINGS_FLOORS_MAP.NO_MAP' | translate"
        [class]="'alert-warning mt-20 mb-20'">
      </app-alert>
    </ng-template>

    <app-loading-small
      *ngIf="isLoadingPageComponent || isLoadingComponent"
      position="absolute"
      top="32px"
      right="30px">
    </app-loading-small>
  </div>
</div>
