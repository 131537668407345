<div class="card card-details-editable">
  <div class="card-body">
    <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.DETAILS' | translate }}</h5>
    <div class="card-content">
      <form [formGroup]="buildingDetailsForm">
        <div class="form-group">
          <label>{{'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.ID_LABEL' | translate}}</label>
          <div>{{building.id}}</div>
        </div>
        <div class="form-group">
          <label>{{'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.CREATED_AT_LABEL' | translate}}</label>
          <div>{{building.createdAt | date:"dd.MM.yyyy HH:mm"}}</div>
        </div>
        <app-input
          [class]="'form-control'"
          [value]='building.name'
          [editable]='this.editable'
          [label]="'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.NAME_LABEL' | translate"
          [placeholder]="'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.NAME_PLACEHOLDER' | translate"
          formControlName="name"
          name="name"
          type="text">
        </app-input>
        <app-input
          [class]="'form-control'"
          [value]='building.location'
          [editable]='this.editable'
          [label]="'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.LOCATION_LABEL' | translate"
          [placeholder]="'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.LOCATION_LABEL' | translate"
          formControlName="location"
          name="location"
          type="text">
        </app-input>
        <div class="form-group">
          <label>{{'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.FLOWERS_AMOUNT_LABEL' | translate}}</label>
          <div>{{flowersCount}}</div>
        </div>
        <div class="form-group">
          <label>{{'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.FLOORS_AMOUNT_LABEL' | translate}}</label>
          <div>{{floorsCount}}</div>
        </div>
        <app-button
          (click)="save()"
          *ngIf="this.editable"
          [disabled]="buildingDetailsForm.invalid"
          [title]="'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.FORM.SAVE' | translate"
          [class]="'btn btn-sm font-weight-bold btn-primary'">
        </app-button>
      </form>
    </div>
    <fa-icon class="edit-cog" [class.edit-cog--active]="editable" [icon]="faCog" (click)="toggleEditable()"></fa-icon>
    <app-loading-small
      *ngIf="isLoadingComponent"
      position="absolute"
      bottom="35px"
      right="30px">
    </app-loading-small>
  </div>
</div>
