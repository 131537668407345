<div class="card card-details-editable">
  <div class="card-body">
    <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.USERS_DETAILS.DETAILS' | translate }}</h5>
    <div class="card-content">
      <form [formGroup]="userDetailsForm">
        <div class="form-group">
          <label>{{'COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.ID_LABEL' | translate}}</label>
          <div>{{user.id}}</div>
        </div>
        <div class="form-group">
          <label>{{'COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.CREATED_AT_LABEL' | translate}}</label>
          <div>{{user.createdAt | date:"dd.MM.yyyy HH:mm"}}</div>
        </div>
        <app-input
          [class]="'form-control'"
          [value]='user.userFullName'
          [editable]='this.editable'
          [label]="'COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.USERNAME_LABEL' | translate"
          [placeholder]="'COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.USERNAME_PLACEHOLDER' | translate"
          formControlName="userFullName"
          name="userFullName"
          type="text">
        </app-input>
        <app-input
          [class]="'form-control'"
          [value]='user.email'
          [editable]='this.editable'
          [label]="'COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.EMAIL_LABEL' | translate"
          [placeholder]="'COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.EMAIL_PLACEHOLDER' | translate"
          formControlName="email"
          name="email"
          type="text">
        </app-input>

        <!--Roles - start-->
        <div class="form-group">
          <label for="isAdmin">{{ 'COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.ROLE' | translate }}</label>
          <app-checkbox
            *ngIf="editable"
            [label]="'COMPONENTS.DASHBOARD.USERS_CREATE.FORM.IS_ADMIN' | translate"
            formControlName="isAdmin"
            name="isAdmin"
            value="1"
            checked="true">
          </app-checkbox>
          <p *ngIf="!editable">
            {{
            user.roles.includes(roleAdmin) ?
            ('COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.ROLE_ADMIN' | translate) :
            ('COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.ROLE_USER' | translate)
            }}
          </p>
        </div>

        <!--Roles - end-->

        <app-button
          (click)="save()"
          *ngIf="this.editable"
          [disabled]="userDetailsForm.invalid"
          [title]="'COMPONENTS.DASHBOARD.USERS_DETAILS.FORM.SAVE' | translate"
          [class]="'btn btn-sm font-weight-bold btn-primary'">
        </app-button>
      </form>
    </div>
    <fa-icon class="edit-cog" [class.edit-cog--active]="editable" [icon]="faCog" (click)="toggleEditable()"></fa-icon>
    <app-loading-small
      *ngIf="isLoadingComponent"
      position="absolute"
      bottom="35px"
      right="30px">
    </app-loading-small>
  </div>
</div>
