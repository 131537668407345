<div class="row mt-60">
  <div class="col-12">
    <div class="card card-details-editable">
      <div class="card-body">
        <app-loading-small
          *ngIf="isLoadingComponent"
          position="absolute"
          top="26px"
          right="34px">
        </app-loading-small>
        <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.USERS_CREATE.GENERAL_INFORMATION' | translate }}</h5>
        <div class="card-content">
          <form [formGroup]="userCreateForm">
            <app-input
              [class]="'form-control'"
              [label]="'COMPONENTS.DASHBOARD.USERS_CREATE.FORM.USERNAME_LABEL' | translate"
              [placeholder]="'COMPONENTS.DASHBOARD.USERS_CREATE.FORM.USERNAME_PLACEHOLDER' | translate"
              formControlName="userFullName"
              name="userFullName"
              type="text">
            </app-input>
            <app-input
              [class]="'form-control'"
              [label]="'COMPONENTS.DASHBOARD.USERS_CREATE.FORM.EMAIL_LABEL' | translate"
              [placeholder]="'COMPONENTS.DASHBOARD.USERS_CREATE.FORM.EMAIL_PLACEHOLDER' | translate"
              formControlName="email"
              name="email"
              type="text">
            </app-input>
            <app-checkbox
              [label]="'COMPONENTS.DASHBOARD.USERS_CREATE.FORM.IS_ADMIN' | translate"
              formControlName="isAdmin"
              name="isAdmin"
              value="1"
              checked="true">
            </app-checkbox>
            <app-button
              (click)="addUser()"
              [disabled]="userCreateForm.invalid"
              [title]="'COMPONENTS.DASHBOARD.USERS_CREATE.FORM.ADD' | translate"
              [class]="'btn btn-sm btn-primary font-weight-bold mt-10'">
            </app-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
