<div class="checkboxes-days">
  <div formControlName="[day][1][{{id}}]" class="form-group">
    <input type="checkbox" name="[day][1][{{id}}]" id="pn-{{id}}">
    <label for="pn-{{id}}">PN</label>
  </div>
  <div formControlName="[day][2][{{id}}]" class="form-group">
    <input type="checkbox" name="[day][2][{{id}}]" id="wt-{{id}}">
    <label for="wt-{{id}}">WT</label>
  </div>
  <div formControlName="[day][3][{{id}}]" class="form-group">
    <input type="checkbox" name="[day][3][{{id}}]" id="sr-{{id}}">
    <label for="sr-{{id}}">SR</label>
  </div>
  <div formControlName="[day][4][{{id}}]" class="form-group">
    <input type="checkbox" name="[day][4][{{id}}]" id="cz-{{id}}">
    <label for="cz-{{id}}">CZ</label>
  </div>
  <div formControlName="[day][5][{{id}}]" class="form-group">
    <input type="checkbox" name="[day][5][{{id}}]" id="pt-{{id}}">
    <label for="pt-{{id}}">PT</label>
  </div>
  <div formControlName="[day][6][{{id}}]" class="form-group">
    <input type="checkbox" name="[day][6][{{id}}]" id="so-{{id}}">
    <label for="so-{{id}}">SO</label>
  </div>
  <div formControlName="[day][7][{{id}}]" class="form-group">
    <input type="checkbox" name="[day][7][{{id}}]" id="nd-{{id}}">
    <label for="nd-{{id}}">ND</label>
  </div>
</div>
