import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuildingsFloorsListOfPointsComponent } from './buildings-floors-list-of-points.component';
import { ButtonModule } from '../../../../ui-components/button/button.module';
import { LoadingSmallModule } from '../../../../ui-components/loading-small/loading-small.module';
import { CreateEditPointInFloorModalModule } from '../../../../ui-components/modals/create-edit-point-in-floor-modal/create-edit-point-in-floor-modal.module';
import { AlertModule } from '../../../../ui-components/alert/alert.module';


@NgModule({
  declarations: [BuildingsFloorsListOfPointsComponent],
  exports: [BuildingsFloorsListOfPointsComponent],
  providers: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    LoadingSmallModule,
    TranslateModule,
    CreateEditPointInFloorModalModule,
    AlertModule
  ]
})
export class BuildingsFloorsListOfPointsModule { }
