import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { faAngleRight, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../../../services/user.service';
import { Pagination } from '../../../../interfaces/pagination';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteModalComponent } from '../../../ui-components/modals/confirm-delete-modal/confirm-delete-modal.component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AuthService } from '../../../../services/auth.service';
import { Subscription } from 'rxjs';
import { Config } from '../../../../../config';


@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit, OnDestroy {
  public faAngleRight = faAngleRight;
  public faUserShield = faUserShield;
  public users;
  public pagination: Pagination;
  public searchForm: FormGroup;
  public checkState: boolean = false;
  public selectedUsers: [] = [];
  public page: number = 1;
  public totalItems: number;
  public totalPages: number;
  public previousPage: number;
  public nextPage: number;
  public currentPage: number;
  public lastPage: number;
  public directionSortValue: string = 'desc';
  public isLoadingComponent: boolean = false;
  public currentUser = null;
  public roleAdmin = Config.ROLE_ADMIN;
  private subscriptionUser$: Subscription;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private modalService: NgbModal,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) { }

  @ViewChild('sortableTableRow') elRefs: ElementRef;

  ngOnInit(): void {
    this.subscriptionUser$ = this.authService.user$.subscribe((user) => {
      this.currentUser = user;
    });
    this.loadUsers();
    this.searchForm = new FormGroup({
      searchUser: new FormControl(''),
    });

    this.searchForm.get('searchUser').valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((userFullName) => {
        this.userService._state.userFullName = userFullName;
        this.userService._state.page = 1;
        this.loadUsers();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptionUser$?.unsubscribe();
    this.userService._state.userFullName = '';
    this.userService._state.page = 1;
  }

  loadUsers() {
    this.isLoadingComponent = true;
    return this.userService.getUsers().subscribe(response => {
      this.users = response.result['hydra:member'];
      this.totalItems = response.result['hydra:totalItems'];
      this.totalPages = response.result['hydra:view']['hydra:last']?.match(/page=(\d*)/)[1] ?? 1;
      this.previousPage = response.result['hydra:view']['hydra:previous']?.match(/page=(\d*)/)[1] ?? false;
      this.nextPage = response.result['hydra:view']['hydra:next']?.match(/page=(\d*)/)[1] ?? false;
      this.lastPage = response.result['hydra:view']['hydra:last']?.match(/page=(\d*)/)[1] ?? false;
      this.currentPage = this.nextPage ? --this.nextPage : ++this.previousPage;
      this.isLoadingComponent = false;
    });
  }

  deleteUsers() {
    const selectedUsers = this.users.filter(user => user.checked).map(p => p.id);
    if (selectedUsers && selectedUsers.length > 0) {
      this.modalService.open(ConfirmDeleteModalComponent, {
        backdropClass: 'light-blue-backdrop',
        centered: true
      }).result.then(() => {
        this.isLoadingComponent = true;
        this.userService.removeUsers(selectedUsers).subscribe(() => {
          this.loadUsers();
          this.isLoadingComponent = false;
        });
      }, () => {});
    }
  }

  checkOne(userId: number) {
    this.users.forEach((user) => {
      if (user.id === userId) {
        user.checked = !user.checked;
      }

      // checked current user = false
      this.currentUserCheckedSetFalse(user);
    });
  }

  checkAll() {
    this.checkState = !this.checkState;
    this.users.forEach(user => user.checked = this.checkState);

    // checked current user = false
    this.users.filter(user => {
      this.currentUserCheckedSetFalse(user);
    });
  }

  checkCheckedUsers() {
    this.selectedUsers = this.users.filter(({ checked }) => checked).map(({ id }) => id);
  }

  changePage(newCurrentPage: number) {
    this.userService._state.page = newCurrentPage;
    this.loadUsers();
  }

  sort(colName: string, directionValue: string, event) {
    this.userService._state.sortBy = 'order[' + colName + ']';
    this.userService._state.sortByDirection = directionValue;

    const sortableElements = this.elRef.nativeElement.querySelectorAll('[sortable]') ?? [];
    const classesToRemove = ['asc', 'desc'];

    sortableElements.forEach(sortableElement => {
      classesToRemove.forEach(classToRemove => {
        this.renderer.removeClass(sortableElement, classToRemove);
      });
    });

    if (directionValue === 'desc') {
      event.target.classList.add('asc');
      event.target.classList.remove('desc');
    } else if (directionValue === 'asc') {
      event.target.classList.add('desc');
      event.target.classList.remove('asc');
    }
    this.directionSortValue = this.directionSortValue === 'asc' ? 'desc' : 'asc';

    this.loadUsers();
  }

  private currentUserCheckedSetFalse(user) {
    if (user.id === this.currentUser.id) {
      user.checked = false;
    }
  }
}

