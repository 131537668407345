<div class="row">
  <div class="col-12">
    <div class="options-bar position-relative">
      <div>
        <form [formGroup]="searchForm">
          <app-input-with-icon
            [placeholder]="'COMPONENTS.DASHBOARD.USERS_TABLE.SEARCH_IN_USERS' | translate"
            formControlName="searchUser"
            name="searchUser"
            icon="search">
          </app-input-with-icon>
        </form>
        <app-loading-small
          *ngIf="isLoadingComponent"
          position="absolute"
          top="-40px"
          right="0">
        </app-loading-small>
      </div>
      <div class="action-buttons">
        <app-button
          [routerLink]="'/users/create'"
          [title]="'COMPONENTS.DASHBOARD.USERS_TABLE.ADD' | translate"
          [class]="'btn-primary font-weight-bold mr-30'"
          icon="add">
        </app-button>
        <app-button
          (click)="deleteUsers()"
          [disabled]="this.selectedUsers?.length <= 0"
          [title]="'COMPONENTS.DASHBOARD.USERS_TABLE.REMOVE' | translate"
          [class]="'btn btn-outline-danger font-weight-bold'"
          icon="remove">
        </app-button>
      </div>
    </div>
  </div>
</div>
<app-loading-content [loading]="isLoadingComponent">
  <div class="row" *ngIf="users?.length > 0; else noUsers">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-borderless table-calla">
        <thead>
        <tr #sortableTableRow>
          <th>
            <input (change)="checkCheckedUsers()" (click)="checkAll()" [checked]="checkState" type="checkbox" class="checkbox checkbox--opacity">
          </th>
          <th sortable (click)="sort('userFullName', directionSortValue, $event)">
            {{ 'COMPONENTS.DASHBOARD.USERS_TABLE.TABLE.USERNAME' | translate }}
          </th>
          <th sortable (click)="sort('email', directionSortValue, $event)">
            {{ 'COMPONENTS.DASHBOARD.USERS_TABLE.TABLE.EMAIL' | translate }}
          </th>
          <th sortable (click)="sort('createdAt', directionSortValue, $event)">
            {{ 'COMPONENTS.DASHBOARD.USERS_TABLE.TABLE.CREATED_AT' | translate }}
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of users">
          <td scope="row">
            <input *ngIf="user.id !== currentUser.id" (change)="checkCheckedUsers()" (click)="checkOne(user.id)" [checked]="user.checked" type="checkbox" class="checkbox">
          </td>
          <td>
            <fa-icon
              *ngIf="user.roles.includes(roleAdmin)"
              class="administrator-icon"
              [icon]="faUserShield"
              ngbTooltip="{{ 'COMPONENTS.DASHBOARD.USERS_TABLE.TABLE.IS_ADMIN' | translate }}"
            ></fa-icon>
            {{ user.userFullName }}
          </td>
          <td>{{ user.email }}</td>
          <td>{{ user.createdAt | date:'dd.MM.yyyy HH:mm' }}</td>
          <td class="table-action">
            <a [routerLink]="['/users', user.id]">
              <fa-icon class="action-angle-right" [icon]="faAngleRight"></fa-icon>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-12">
    <div class="d-flex justify-content-end">
      <app-pagination
        (currentPageChange)="changePage($event)"
        [(currentPage)]="this.currentPage"
        [lastPage]="this.lastPage"
        [nextPage]="this.nextPage"
        [previousPage]="this.previousPage"
        [totalPages]="this.totalPages">
      </app-pagination>
    </div>
  </div>
</div>
</app-loading-content>

<ng-template #noUsers>
  <app-alert
    [title]="'COMPONENTS.DASHBOARD.USERS_TABLE.NO_USERS' | translate"
    [class]="'alert-warning mt-20 mb-20'">
  </app-alert>
</ng-template>
