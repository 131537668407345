import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification, NotificationType } from '../interfaces/notification';
import { uniqueID } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private subject$: Subject<void> = new Subject<void>();

  public notifications: Notification[] = [];

  public info(message: string, timeout?: number): Notification {
    return this.next(NotificationType.info, message, timeout);
  }

  public success(message: string, timeout?: number): Notification {
    return this.next(NotificationType.success, message, timeout);
  }

  public warning(message: string, timeout?: number): Notification {
    return this.next(NotificationType.warning, message, timeout);
  }

  public error(message: string, timeout?: number): Notification {
    return this.next(NotificationType.error, message, timeout);
  }

  public close(notification: Notification): void {
    this.notifications = this.notifications.filter(({ id }) => notification.id !== id);
    this.subject$.next();
  }

  public getObservable$(): Observable<void> {
    return this.subject$.asObservable();
  }

  private next(type: NotificationType, message: string, timeout: number = Notification.DEFAULT): Notification {
    const notification = new Notification(uniqueID(), type, message, timeout);

    this.notifications.push(notification);
    this.subject$.next();

    if (notification.timeout > -1) {
      setTimeout(() => this.close(notification), notification.timeout);
    }

    return notification;
  }
}
