import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputModule } from '../../input/input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateEditDefaultTaskModalComponent } from './create-edit-default-task-modal.component';


@NgModule({
  declarations: [CreateEditDefaultTaskModalComponent],
  exports: [CreateEditDefaultTaskModalComponent],
  imports: [
    TranslateModule,
    CommonModule,
    ButtonModule,
    FontAwesomeModule,
    InputModule,
    ReactiveFormsModule,
  ]
})
export class CreateEditDefaultTaskModalModule { }
