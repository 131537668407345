<button class="btn {{ class }}" [disabled]="disabled">
  <!--Icons - start-->
  <fa-icon class="mr-10" *ngIf="icon == 'add'" [icon]="faPlus"></fa-icon>
  <fa-icon class="mr-10" *ngIf="icon == 'remove'" [icon]="faTrash"></fa-icon>
  <fa-icon class="mr-10" *ngIf="icon == 'upload'" [icon]="faUpload"></fa-icon>
  <fa-icon class="mr-10" *ngIf="icon == 'eye'" [icon]="faEye"></fa-icon>
  <fa-icon class="mr-10" *ngIf="icon == 'expand'" [icon]="faExpand"></fa-icon>
  <fa-icon class="mr-10" *ngIf="icon == 'compress'" [icon]="faCompress"></fa-icon>
  <!--Icons - end-->
  {{ title }}
</button>
