import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UsersTableComponent } from './users-table.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputWithIconModule } from '../../../ui-components/input-with-icon/input-with-icon.module';
import { PaginationModule } from '../../../ui-components/pagination/pagination.module';
import { AlertModule } from '../../../ui-components/alert/alert.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDeleteModalModule } from '../../../ui-components/modals/confirm-delete-modal/confirm-delete-modal.module';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';
import { ButtonModule } from '../../../ui-components/button/button.module';
import { LoadingContentModule } from '../../../loading-content/loading-content.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [UsersTableComponent],
  exports: [UsersTableComponent],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    InputWithIconModule,
    PaginationModule,
    AlertModule,
    ReactiveFormsModule,
    FormsModule,
    ConfirmDeleteModalModule,
    LoadingSmallModule,
    ButtonModule,
    LoadingContentModule,
    NgbTooltipModule
  ]
})
export class UsersTableModule { }
