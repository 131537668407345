import { Component, OnInit } from '@angular/core';
import { FlowerService } from '../../../../../services/flower.service';
import { Flower } from '../../../../../models/flower';
import { ActivatedRoute } from '@angular/router';
import { Floor } from '../../../../../models/floor';
import { Building } from '../../../../../models/building';
import { FloorService } from '../../../../../services/floor.service';

@Component({
  selector: 'app-buildings-floors-show-page',
  templateUrl: './buildings-floors-show.page.html',
  styleUrls: ['./buildings-floors-show.page.scss'],
})
export class BuildingsFloorsShowPageComponent implements OnInit {
  public isLoadingPageComponent: boolean = false;
  public flowers: Flower[];
  public floor: Floor;
  public building = Building;
  public addedFlower: Flower;
  public removedFlower: Flower;
  public updatedFlower: Flower;

  constructor(
    private flowerService: FlowerService,
    private floorService: FloorService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.flowerService.hoverFlower.subscribe();

    this.isLoadingPageComponent = true;
    this.route.data.subscribe(data => {
      this.floor = data.floor;
      this.flowers = data.floor.flowers.filter(flower => flower.status);
      this.flowers.sort((a, b) => a.id - b.id);
      this.isLoadingPageComponent = false;
    });
  }

  addedPoint(flower): void {
    this.addedFlower = flower.response;
  }

  removedPoint(flower): void {
    this.removedFlower = flower.response.flower;
  }

  updatedPoint(flower): void {
    this.updatedFlower = flower.response.flower;
  }
}
