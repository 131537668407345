import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';
import { MatchPassword } from '../../validators/match-password.validator';
import { finalize } from 'rxjs/operators';
import { ValidateEmail } from '../../validators/email.validator';
import { ValidateSixNumbers } from '../../validators/six-numbers.validator';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordForm: FormGroup;
  public codeForm: FormGroup;
  public showRepeatPassword = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, ValidateEmail]),
      code: new FormControl('', [Validators.required, ValidateSixNumbers]),
      newPassword: new FormControl('', [Validators.required]),
      repeatPassword: new FormControl('', [Validators.required]),
    }, {
      validators: [MatchPassword('newPassword', 'repeatPassword')]
    });
  }

  public sendNewPassword(): void {
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.loadingService.start();
    this.authService
      .changePassword(this.changePasswordForm.value)
      .pipe(finalize(() => this.loadingService.stop())).subscribe();
  }
}
