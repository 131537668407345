<div class="modal-body">
  <button
    (click)="modal.dismiss('Cancel click')"
    type="button"
    class="close-modal"
    aria-describedby="modal-title">
    <fa-icon class="close-icon" [icon]="faTimes"></fa-icon>
  </button>
  <h3 class="font-weight-bold text-center">{{ flower ? ('COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.UPDATE_POINT' | translate) : ('COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.ADD_NEW_POINT' | translate) }}</h3>

  <form [formGroup]="floorPointCreateForm">
    <app-input
      [class]="'form-control'"
      [label]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.POINT_NAME' | translate"
      [placeholder]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.ENTER_POINT_NAME' | translate"
      formControlName="title"
      name="title"
      type="text">
    </app-input>

    <div class="row">
      <div class="col-12 col-md-6">
        <app-input
          [class]="'form-control'"
          [label]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.MIN_TEMP' | translate"
          [placeholder]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.MIN_TEMP_ENTER' | translate"
          formControlName="minTemperature"
          name="minTemperature"
          type="text">
        </app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input
          [class]="'form-control'"
          [label]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.MAX_TEMP' | translate"
          [placeholder]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.MAX_TEMP_ENTER' | translate"
          formControlName="maxTemperature"
          name="maxTemperature"
          type="text">
        </app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input
          [class]="'form-control'"
          [label]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.MIN_MOISTURE' | translate"
          [placeholder]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.MIN_MOISTURE_ENTER' | translate"
          formControlName="minMoisture"
          name="minMoisture"
          type="text">
        </app-input>
      </div>
      <div class="col-12 col-md-6">
        <app-input
          [class]="'form-control'"
          [label]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.MAX_MOISTURE' | translate"
          [placeholder]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.MAX_MOISTURE_ENTER' | translate"
          formControlName="maxMoisture"
          name="maxMoisture"
          type="text">
        </app-input>
      </div>
    </div>
  </form>

  <app-alert
    *ngIf="!flower"
    [title]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.INFO' | translate"
    [class]="'alert-warning mt-20 mb-20'">
  </app-alert>

  <div class="tasks" *ngIf="flower">
    <app-loading-content [loading]="loadingTasks && loadingDefaultTasks">
      <ng-container *ngFor="let day of daysOfWeek; let i = index">
        <ng-container *ngTemplateOutlet="dayOfWeek; context: { index: i, day: day }"></ng-container>
      </ng-container>
    </app-loading-content>
  </div>

  <div class="d-flex justify-content-end mt-20">
    <app-button (click)="savePoint(flower)"
                [disabled]="floorPointCreateForm.invalid"
                [title]="flower ? ('COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.UPDATE' | translate) : ('COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.ADD' | translate)"
                [class]="'btn btn-primary font-weight-bold btn-sm mr-20'">
    </app-button>
    <app-button
      (click)="removePoint(flower)"
      *ngIf="flower"
      [title]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.REMOVE' | translate"
      [class]="'btn btn-outline-danger font-weight-bold btn-sm'">
    </app-button>
  </div>
</div>

<ng-template #dayOfWeek let-index="index" let-day="day">
  <div class="card">
    <div class="card-body">
      <fa-icon
        *ngIf="day.isLoading"
        class="mr-10 fa-spin text-primary loading-day"
        [icon]="faCircleNotch">
      </fa-icon>

      <label>
        <input type="checkbox" #daySelected [checked]="!!day.tasks.length" (change)="cdr.detectChanges()">
        {{ day.name | translate }}
      </label>
      <ng-container *ngIf="daySelected.checked">
        <ul *ngIf="day.tasks.length">
          <li *ngFor="let task of day.tasks">
            <fa-icon
              (click)="removeTask(task.id, day)"
              class="mr-10"
              [class.fa-spin]="removingTasks.includes(task.id)"
              [icon]="removingTasks.includes(task.id) ? faCircleNotch : faTrash">
            </fa-icon>
            <span>{{ task.description }}</span>
          </li>
        </ul>
        <div class="new-task">

          <div>
            <select
              [formControl]="day.control"
              class="form-control">
              <option
                *ngFor="let defaultTask of defaultTasks; let i = index"
                value="{{defaultTask.description}}"
              >
                {{defaultTask.description}}
              </option>
              <option value="0">{{ 'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.OTHER' | translate }}</option>
            </select>

            <app-input
              *ngIf="day.control.value === '0'"
              [class]="'form-control mt-10'"
              [placeholder]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.ENTER_NEW_TASK_NAME' | translate"
              [formControl]="day.control_other"
              type="text">
            </app-input>
          </div>

          <div>
            <app-button
              (click)="createTask(index)"
              [title]="'COMPONENTS.DASHBOARD.CREATE_POINT_IN_FLOOR_MODAL.ADD' | translate"
              class="btn btn-primary btn-sm">
            </app-button>
          </div>

        </div>
      </ng-container>
    </div>
  </div>


</ng-template>
