<div class="container" id="login-container">
  <div class="row">
    <div class="col-12 col-lg-6 offset-lg-3">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-7">
              <form [formGroup]="form" class="h-100 d-flex flex-column justify-content-between">
                <div class="row mb-60">
                  <div class="col-12">
                    <img src="assets/images/calla2.png" class="img-fluid logo">
                  </div>
                </div>
                <div class="row mb-40">
                  <div class="col-12">
                    <div class="mb-20">
                      <app-input
                        [class]="'form-control'"
                        [placeholder]="'PAGES.LOGIN.EMAIL' | translate"
                        formControlName="username"
                        name="username"
                        type="text">
                      </app-input>
                    </div>
                    <div class="mb-20">
                      <app-input
                        [class]="'form-control'"
                        [placeholder]="'PAGES.LOGIN.REMIND_PASSWORD' | translate"
                        formControlName="password"
                        name="password"
                        type="password">
                      </app-input>
                    </div>
                    <a [routerLink]="['/forgot-password']">{{ 'PAGES.LOGIN.REMIND_PASSWORD' | translate }}</a>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <app-button
                      (click)="login()"
                      [disabled]="form.invalid"
                      [title]="'PAGES.LOGIN.LOGIN' | translate"
                      [class]="'btn btn-lg btn-primary font-weight-bold'">
                    </app-button>
                  </div>
                </div>
              </form>
            </div>
            <div class="d-none d-md-flex col-md-5">
              <img src="assets/images/loginbg.png" class="img-fluid flower">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
