import { NgModule } from '@angular/core';
import { BreadcrumbModule } from '../../../../components/ui-components/breadcrumb/breadcrumb.module';
import { UsersShowPageComponent } from './users-show.page';
import { UsersGeneralInformationModule } from '../../../../components/dashboard/users/users-general-information/users-general-information.module';
import { UsersWorkScheduleModule } from '../../../../components/dashboard/users/users-work-schedule/users-work-schedule.module';


@NgModule({
  declarations: [UsersShowPageComponent],
  exports: [UsersShowPageComponent],
  imports: [
    BreadcrumbModule,
    UsersGeneralInformationModule,
    UsersWorkScheduleModule
  ]
})
export class UsersShowPageModule { }
