import { NgModule } from '@angular/core';
import { BreadcrumbModule } from '../../../components/ui-components/breadcrumb/breadcrumb.module';
import { ButtonModule } from '../../../components/ui-components/button/button.module';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ReportsPageComponent } from './reports.page';
import { ReportsTableModule } from '../../../components/dashboard/reports/reports-table/reports-table.module';


@NgModule({
  declarations: [ReportsPageComponent],
  exports: [ReportsPageComponent],
  imports: [
    TranslateModule,
    BreadcrumbModule,
    ReportsTableModule,
    ButtonModule,
    RouterModule,
    FontAwesomeModule
  ]
})
export class ReportsPageModule {
}
