import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() currentPage: number;
  @Output() currentPageChange = new EventEmitter<number>();
  @Input() lastPage: number;
  @Input() totalPages: number;
  @Input() nextPage: number;
  @Input() previousPage: number;

  public pageNumber: number = 1;
  public faChevronLeft = faChevronLeft;
  public faChevronRight = faChevronRight;

  next() {
    if (this.currentPage < this.lastPage) {
      this.currentPage++;
      this.currentPageChange.emit(this.currentPage);
    }
  }

  previous() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.currentPageChange.emit(this.currentPage);
    }
  }

  changePageInput() {
    if (this.pageNumber <= this.lastPage && this.pageNumber >= 1) {
      this.currentPageChange.emit(this.pageNumber);
    } else {
      this.pageNumber = this.currentPage;
      this.currentPageChange.emit(this.currentPage);
    }
  }
}
