import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '../../../ui-components/input/input.module';
import { ButtonModule } from '../../../ui-components/button/button.module';
import { CheckboxModule } from '../../../ui-components/checkbox/checkbox.module';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';
import { BuildingsCreateComponent } from './buildings-create.component';


@NgModule({
  declarations: [BuildingsCreateComponent],
  exports: [BuildingsCreateComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    InputModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    CheckboxModule,
    LoadingSmallModule,
    TranslateModule
  ]
})
export class BuildingsCreateModule { }
