import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Floor } from '../models/floor';
import { catchError, tap } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { Flower } from '../models/flower';

@Injectable({
  providedIn: 'root',
})
export class FloorService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService
  ) {}

  getFloor(floorId: string): Observable<Floor[]> {
    return this.httpClient.get<Floor[]>(this.apiService.url('floors/' + floorId));
  }

  createFloor(data): Observable<Floor> {
    return this.httpClient.post<Floor>(this.apiService.url('floors'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.FLOOR.NOTIFICATIONS.FLOOR_CREATED');
        })
      );
  }

  updateFloor(floorId, data): Observable<Floor> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');
    return this.httpClient.patch<Floor>(this.apiService.url('floors/' + floorId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.FLOOR.NOTIFICATIONS.FLOOR_UPDATED');
        })
      );
  }

  removeFloor(floorId: number): Observable<Floor> {
    const data = {
      status: 0
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');

    return this.httpClient.patch<Floor>(this.apiService.url('floors/' + floorId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.FLOWER.NOTIFICATIONS.OBJECT_DELETED');
        })
      );
  }

  resetPointFloor(floorId): Observable<Floor> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post<Floor>(this.apiService.url('floors/' + floorId + '/reset_flowers'), {}, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.FLOOR.NOTIFICATIONS.POINTS_WAS_RESETED');
        })
      );
  }
}
