import { Component, Input } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() backButton: boolean = false;

  public faAngleLeft = faAngleLeft;

  constructor(
    public readonly breadcrumbService: BreadcrumbService,
    private location: Location
  ) {
  }

  goBack() {
    this.location.back();
  }

}
