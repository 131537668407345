import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirm-upload-map-modal',
  templateUrl: './confirm-upload-map-modal.component.html',
  styleUrls: ['./confirm-upload-map-modal.component.scss']
})
export class ConfirmUploadMapModalComponent {
  public faTimes = faTimes;

  constructor(public modal: NgbActiveModal) { }
}
