<div class="container">
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-12 col-lg-8">
      <div class="card">
        <div class="card-body text-center">
          <h1 class="mb-10">{{'PAGES.WELCOME.WELCOME_IN_PANEL' | translate}}</h1>
          <h5 class="mb-40">{{'PAGES.WELCOME.SELECT_SECTION' | translate}}</h5>
          <div class="row">


            <div class="col-12 col-md-6 col-buildings">
              <div class="section" [routerLink]="'/buildings'">
                <fa-icon class="icon" [icon]="faBuilding"></fa-icon>
                <h2 class="title">{{'PAGES.WELCOME.BUILDINGS' | translate}}</h2>
                <p class="description">{{'PAGES.WELCOME.MANAGE_BUILDINGS' | translate}}</p>
              </div>
            </div>
            <div class="col-12 col-md-6 col-users">
              <div class="section" [routerLink]="'/users'">
                <fa-icon class="icon" [icon]="faUser"></fa-icon>
                <h2 class="title">{{'PAGES.WELCOME.USERS' | translate}}</h2>
                <p class="description">{{'PAGES.WELCOME.MANAGE_USERS' | translate}}</p>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
