import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Pagination } from '../../../../interfaces/pagination';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ConfirmDeleteModalComponent } from '../../../ui-components/modals/confirm-delete-modal/confirm-delete-modal.component';
import { BuildingService } from '../../../../services/building.service';

@Component({
  selector: 'app-buildings-table',
  templateUrl: './buildings-table.component.html',
  styleUrls: ['./buildings-table.component.scss']
})
export class BuildingsTableComponent implements OnInit, OnDestroy{
  public faAngleRight = faAngleRight;
  public buildings;
  public pagination: Pagination;
  public searchForm: FormGroup;
  public checkState: boolean = false;
  public selectedBuildings: [] = [];
  public page: number = 1;
  public totalItems: number;
  public totalPages: number;
  public previousPage: number;
  public nextPage: number;
  public currentPage: number;
  public lastPage: number;
  public directionSortValue: string = 'desc';
  public isLoadingComponent: boolean = false;

  constructor(
    private buildingService: BuildingService,
    private modalService: NgbModal,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) { }

  @ViewChild('sortableTableRow') elRefs: ElementRef;

  ngOnInit(): void {
    this.loadBuildings();
    this.searchForm = new FormGroup({
      searchBuilding: new FormControl(''),
    });

    this.searchForm.get('searchBuilding').valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((name) => {
        this.buildingService._state.name = name;
        this.buildingService._state.page = 1;
        this.loadBuildings();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.buildingService._state.name = '';
    this.buildingService._state.page = 1;
  }

  loadBuildings() {
    this.isLoadingComponent = true;
    return this.buildingService.getBuildings().subscribe(response => {
      this.buildings = response.result['hydra:member'];

      // Floors count
      if (this.buildings.length > 0) {
        this.buildings.forEach((building) => {
          const floorsCount = building.floors.filter(floor => floor.status).length;
          building['floorsCount'] = floorsCount;
        });
      }

      // Flowers count
      if (this.buildings.length > 0) {
        this.buildings.forEach((building) => {
          let flowersCount: number = 0;
          building.floors.forEach(floor => {
            // todo - waiting for backend
            flowersCount += floor.flowers.filter(flower => flower.status).length;
          });
          building['flowersCount'] = flowersCount;
        });
      }

      this.totalItems = response.result['hydra:totalItems'];
      this.totalPages = response.result['hydra:view']['hydra:last']?.match(/page=(\d*)/)[1] ?? 1;
      this.previousPage = response.result['hydra:view']['hydra:previous']?.match(/page=(\d*)/)[1] ?? false;
      this.nextPage = response.result['hydra:view']['hydra:next']?.match(/page=(\d*)/)[1] ?? false;
      this.lastPage = response.result['hydra:view']['hydra:last']?.match(/page=(\d*)/)[1] ?? false;
      this.currentPage = this.nextPage ? --this.nextPage : ++this.previousPage;
      this.isLoadingComponent = false;
    });
  }

  deleteBuildings() {
    const selectedBuildings = this.buildings.filter(building => building.checked).map(p => p.id);
    if (selectedBuildings && selectedBuildings.length > 0) {
      this.modalService.open(ConfirmDeleteModalComponent, {
        backdropClass: 'light-blue-backdrop',
        centered: true
      }).result.then(() => {
        this.isLoadingComponent = true;
        this.buildingService.removeBuildings(selectedBuildings).subscribe(() => {
          this.loadBuildings();
          this.isLoadingComponent = false;
        });
      }, () => {});
    }
  }

  checkOne(buildingId: number) {
    this.buildings.forEach((building) => {
      if (building.id === buildingId) {
        building.checked = !building.checked;
      }
    });
  }

  checkAll() {
    this.checkState = !this.checkState;
    this.buildings.forEach(building => building.checked = this.checkState);
  }

  checkCheckedBuildings() {
    this.selectedBuildings = this.buildings.filter(({ checked }) => checked).map(({ id }) => id);
  }

  changePage(newCurrentPage: number) {
    this.buildingService._state.page = newCurrentPage;
    this.loadBuildings();
  }

  sort(colName: string, directionValue: string, event) {
    this.buildingService._state.sortBy = 'order[' + colName + ']';
    this.buildingService._state.sortByDirection = directionValue;

    const sortableElements = this.elRef.nativeElement.querySelectorAll('[sortable]') ?? [];
    const classesToRemove = ['asc', 'desc'];

    sortableElements.forEach(sortableElement => {
      classesToRemove.forEach(classToRemove => {
        this.renderer.removeClass(sortableElement, classToRemove);
      });
    });

    if (directionValue === 'desc') {
      event.target.classList.add('asc');
      event.target.classList.remove('desc');
    } else if (directionValue === 'asc') {
      event.target.classList.add('desc');
      event.target.classList.remove('asc');
    }
    this.directionSortValue = this.directionSortValue === 'asc' ? 'desc' : 'asc';

    this.loadBuildings();
  }
}
