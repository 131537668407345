import { Component, Input, Self } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-input-with-icon',
  templateUrl: './input-with-icon.component.html',
  styleUrls: ['./input-with-icon.component.scss']
})
export class InputWithIconComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() name: string;

  public faSearch = faSearch;

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
}
