<div class="form-check">
  <input type="hidden" value="0" [name]="name">
  <input
    class="form-check-input"
    type="checkbox"
    id="{{ name }}"
    [formControl]="ngControl.control"
    [checked]="checked"
    [value]="value"
    [name]="name">
  <label class="form-check-label" for="{{ name }}">
    {{ label }}
  </label>
</div>
