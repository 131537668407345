import { NgModule } from '@angular/core';
import { LoginPageComponent } from './login.page';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '../../components/ui-components/input/input.module';
import { ButtonModule } from '../../components/ui-components/button/button.module';


@NgModule({
  declarations: [LoginPageComponent],
  exports: [LoginPageComponent],
  imports: [
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    ButtonModule
  ]
})
export class LoginModule { }
