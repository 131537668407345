<div class="row">
  <div class="col-12">
    <div class="options-bar position-relative">
      <div>
        <form [formGroup]="searchForm">
          <app-input-with-icon
            [placeholder]="'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.SEARCH_IN_BUILDINGS' | translate"
            formControlName="searchBuilding"
            name="searchBuilding"
            icon="search">
          </app-input-with-icon>
        </form>
        <app-loading-small
          *ngIf="isLoadingComponent"
          position="absolute"
          top="-40px"
          right="0">
        </app-loading-small>
      </div>
      <div class="action-buttons">
        <app-button
          [routerLink]="'/buildings/create'"
          [title]="'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.ADD' | translate"
          [class]="'btn-primary font-weight-bold mr-30'"
          icon="add">
        </app-button>
        <app-button
          (click)="deleteBuildings()"
          [disabled]="this.selectedBuildings?.length <= 0"
          [title]="'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.REMOVE' | translate"
          [class]="'btn btn-outline-danger font-weight-bold'"
          icon="remove">
        </app-button>
      </div>
    </div>
  </div>
</div>
<app-loading-content [loading]="isLoadingComponent">
  <div class="row" *ngIf="buildings?.length > 0; else noBuildings">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-borderless table-calla">
          <thead>
          <tr #sortableTableRow>
            <th>
              <input (change)="checkCheckedBuildings()" (click)="checkAll()" [checked]="checkState" type="checkbox" class="checkbox checkbox--opacity">
            </th>
            <th sortable (click)="sort('name', directionSortValue, $event)">
              {{ 'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.TABLE.NAME' | translate }}
            </th>
            <th sortable (click)="sort('location', directionSortValue, $event)">
              {{ 'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.TABLE.ADDRESS' | translate }}
            </th>
            <th sortable (click)="sort('createdAt', directionSortValue, $event)">
              {{ 'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.TABLE.CREATED_AT' | translate }}
            </th>
            <th>
              {{ 'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.TABLE.FLOORS' | translate }}
            </th>
            <th>
              {{ 'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.TABLE.FLOWERS' | translate }}
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let building of buildings">
            <td scope="row p-relative">
              <input (change)="checkCheckedBuildings()" (click)="checkOne(building.id)" [checked]="building.checked" type="checkbox" class="checkbox">
            </td>
            <td>{{ building.name }}</td>
            <td>{{ building.location }}</td>
            <td>{{ building.createdAt | date:'dd.MM.yyyy HH:mm' }}</td>
            <td>{{ building.floorsCount || building.floorsCount === 0 ? building.floorsCount : '-'}}</td>
            <td>{{ building.flowersCount || building.flowersCount === 0 ? building.flowersCount : '-' }}</td>
            <td class="table-action">
              <a [routerLink]="['/buildings', building.id]">
                <fa-icon class="action-angle-right" [icon]="faAngleRight"></fa-icon>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <app-pagination
          (currentPageChange)="changePage($event)"
          [(currentPage)]="this.currentPage"
          [lastPage]="this.lastPage"
          [nextPage]="this.nextPage"
          [previousPage]="this.previousPage"
          [totalPages]="this.totalPages">
        </app-pagination>
      </div>
    </div>
  </div>
</app-loading-content>

<ng-template #noBuildings>
  <app-alert
    [title]="'COMPONENTS.DASHBOARD.BUILDINGS_TABLE.NO_BUILDINGS' | translate"
    [class]="'alert-warning mt-20 mb-20'">
  </app-alert>
</ng-template>
