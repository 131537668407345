import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../../../models/user';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { finalize } from 'rxjs/operators';
import { ValidateEmail } from '../../../../validators/email.validator';

@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.scss']
})
export class UsersCreateComponent implements OnInit {
  public user: User;
  public userCreateForm: FormGroup;
  public isLoadingComponent: boolean = false;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.user = data.user;
    });

    this.userCreateForm = new FormGroup({
      userFullName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, ValidateEmail]),
      isAdmin: new FormControl(false),
    });
  }

  addUser() {
    if (this.userCreateForm.invalid) {
      return this.userCreateForm.markAllAsTouched();
    }

    this.isLoadingComponent = true;
    this.userService.createUser(this.userCreateForm.value).pipe(finalize(() => {
      this.userCreateForm.reset();
      this.isLoadingComponent = false;
    })).subscribe();
  }
}
