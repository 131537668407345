import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmUploadMapModalComponent } from './confirm-upload-map-modal.component';


@NgModule({
  declarations: [ConfirmUploadMapModalComponent],
  exports: [ConfirmUploadMapModalComponent],
  imports: [
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    ButtonModule
  ]
})
export class ConfirmUploadMapModalModule { }
