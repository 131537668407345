import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private static readonly TOKEN_KEY = 'access_token';
  private jwtHelper: JwtHelperService = new JwtHelperService();
  public get token(): string {
    const token = localStorage.getItem(TokenService.TOKEN_KEY);

    return token ? token : '';
  }

  public set token(token: string) {
    localStorage.setItem(TokenService.TOKEN_KEY, token);
  }

  public remove(): void {
    localStorage.removeItem(TokenService.TOKEN_KEY);
  }

  public isActive(): boolean {
    return !this.jwtHelper.isTokenExpired(this.token);
  }
}
