<div class="form-group">
  <label *ngIf="label?.length > 0" for="{{ name }}">{{ label }}</label>
  <input
    *ngIf="editable"
    [class.is-invalid]="ngControl.touched && ngControl.invalid"
    [formControl]="ngControl.control"
    [name]="name"
    [type]="type"
    [class]="class"
    [placeholder]="placeholder"
    [value]="value">

  <!--Email - error-->
  <div *ngIf="ngControl.control.errors?.email" class="invalid-feedback">
    {{ 'VALIDATION.MUST_BE_EMAIL' | translate }}
  </div>

  <!--Required - error-->
  <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">
    {{ 'VALIDATION.FIELD_IS_REQUIRED' | translate }}
  </div>

  <!--Six numbers - error-->
  <div *ngIf="ngControl.control.errors?.sixNumbers" class="invalid-feedback">
    {{ 'VALIDATION.MUST_CONTAIN_SIX_NUMBERS' | translate }}
  </div>

  <!--Three Chars - error-->
  <div *ngIf="ngControl.control.errors?.threeChars" class="invalid-feedback">
    {{ 'VALIDATION.MUST_CONTAIN_THREE_CHARS' | translate }}
  </div>

  <!--Password Mismatch - error-->
  <div *ngIf="ngControl.control.errors?.passwordMismatch" class="invalid-feedback">
    {{ 'VALIDATION.PASSWORDS_DO_NOT_MATCH' | translate }}
  </div>

  <!--Default task exist - error-->
  <div *ngIf="ngControl.control.errors?.defaultTaskExistValidation" class="invalid-feedback">
    {{ 'VALIDATION.DEFAULT_TASK_EXIST' | translate }}
  </div>

  <!--Only numbers - error-->
  <div *ngIf="ngControl.control.errors?.onlyNumbers" class="invalid-feedback">
    {{ 'VALIDATION.ONLY_NUMBERS' | translate }}
  </div>

  <!--Percentage - error-->
  <div *ngIf="ngControl.control.errors?.percentage" class="invalid-feedback">
    {{ 'VALIDATION.ONLY_PERCENTAGE' | translate }}
  </div>


  <p *ngIf="!editable">{{ value }}</p>

</div>
