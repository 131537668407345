import { NgModule } from '@angular/core';
import { NotFoundPageComponent } from './not-found.page';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [NotFoundPageComponent],
  exports: [NotFoundPageComponent],
  imports: [
    TranslateModule,
  ]
})
export class NotFoundModule { }
