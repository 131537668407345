import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';
import { finalize } from 'rxjs/operators';
import { ValidateEmail } from '../../validators/email.validator';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPageComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, ValidateEmail]),
      password: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }

  public login(): void {
    if (this.form.invalid) {
      return;
    }

    this.loadingService.start();
    this.authService
      .login$(this.form.value)
      .pipe(finalize(() => {
        this.router.navigate(['welcome']);
        this.loadingService.stop();
      })).subscribe();
  }
}
