import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadMapModalComponent } from '../../../../ui-components/modals/upload-map-modal/upload-map-modal.component';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Flower } from '../../../../../models/flower';
import { FlowerService } from '../../../../../services/flower.service';
import { Floor } from '../../../../../models/floor';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { NotificationsService } from '../../../../../services/notifications.service';
import { Notification } from '../../../../../interfaces/notification';
import { Config } from '../../../../../../config';

@Component({
  selector: 'app-buildings-floors-map',
  templateUrl: './buildings-floors-map.component.html',
  styleUrls: ['./buildings-floors-map.component.scss']
})
export class BuildingsFloorsMapComponent implements OnInit {
  @Input('flowers') set _flowers(flowers: Flower[]) {
    this.flowers = flowers.map((flower) => ({
      ...flower,
      locationX: flower.locationX === 0 ? flower.locationX : flower.locationX - Config.MARKER_NEEDLE_X,
      locationY: flower.locationY === 0 ? flower.locationY : flower.locationY - Config.MARKER_NEEDLE_Y,
    }));
  }
  @Input('addedFlower') set _addedFlower(addedFlower: Flower) {
    if (addedFlower) {
      this.flowers.push(addedFlower);
    }
  }
  @Input('removedFlower') set _removedFlower(removedFlower: Flower) {
    if (removedFlower) {
      this.flowers = this.flowers.filter(flower => flower.id !== removedFlower.id);
    }
  }
  @Input('updatedFlower') set _updatedFlower(updatedFlower: Flower) {
    if (updatedFlower) {
      this.flowers.map(flower => {
        if (flower.id === updatedFlower.id) {
          flower.title = updatedFlower.title;
          flower.minTemperature = updatedFlower.minTemperature;
          flower.maxTemperature = updatedFlower.maxTemperature;
          flower.minMoisture = updatedFlower.minMoisture;
          flower.maxMoisture = updatedFlower.maxMoisture;
        }
      });
    }
  }
  @Input() isLoadingPageComponent: boolean;
  @ViewChild('imgMap') imgMap: ElementRef;
  @ViewChild('fullScreenOverlay') fullScreenOverlay: ElementRef<HTMLDivElement>;
  @ViewChild('map') map: ElementRef<HTMLDivElement>;

  public flowers: Flower[];
  public faMapMarkerAlt = faMapMarkerAlt;

  public isLoadingComponent: boolean = false;
  public floor: Floor;
  public floorImage: string = null;

  public fullScreenMode: boolean = false;
  private fullScreenNotification: Notification;

  constructor(
    private modalService: NgbModal,
    public flowerService: FlowerService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
  ) {
  }

  ngOnInit(): void {
    this.flowerService.hoverFlower.subscribe();

    this.route.data.subscribe(data => {
      this.floor = data.floor;
      if (this.floor.photo?.contentUrl) {
        this.floorImage = environment.url + this.floor.photo.contentUrl;
      }
    });
  }

  openFullScreenMode() {
    if (!this.fullScreenMode) {
      this.fullScreenMode = true;
      document.querySelector('html').style.overflow = 'hidden';
      this.fullScreenNotification = this.notificationsService.info('Kliknij [ESC] aby wyjść', Notification.NEVER);
      setTimeout(() => this.centerMapInFullScreenMode());
    }
  }

  @HostListener('window:keydown.escape')
  closeFullScreenMode() {
    if (this.fullScreenMode) {
      this.fullScreenMode = false;
      document.querySelector('html').style.overflow = 'auto';
      this.notificationsService.close(this.fullScreenNotification);
      delete this.fullScreenNotification;
      this.map.nativeElement.style.transform = '';
    }
  }

  @HostListener('window:resize')
  centerMapInFullScreenMode() {
    if (this.fullScreenMode) {
      let offsetY = 0;
      let offsetX = 0;

      if (this.map.nativeElement.clientWidth < this.fullScreenOverlay.nativeElement.clientWidth) {
        offsetX = Math.floor((this.fullScreenOverlay.nativeElement.clientWidth - this.map.nativeElement.clientWidth) / 2);
      }

      if (this.map.nativeElement.clientHeight < this.fullScreenOverlay.nativeElement.clientHeight) {
        offsetY = Math.floor((this.fullScreenOverlay.nativeElement.clientHeight - this.map.nativeElement.clientHeight) / 2);
      }

      this.map.nativeElement.style.transform = `translateX(${offsetX}px) translateY(${offsetY}px)`;
    }
  }

  onDragStart(flower) {
    this.flowerService.onStartDrag(flower.id);
  }

  onDragEnd(event: CdkDragEnd, point: Flower) {
    this.isLoadingComponent = true;
    const element = event.source.getRootElement();
    const transformTranslate3dValue = element.style.transform.match(/translate3d\((\d+)px, (\d+)px, \d+px\)/);

    let x = 0;
    let y = 0;

    if (transformTranslate3dValue) {
      x = parseInt(transformTranslate3dValue[1], 10);
      y = parseInt(transformTranslate3dValue[2], 10);
    }

    this.flowerService.onEndDrag();

    // Update coordinates
    const preparedPoint: Flower = {
      locationX: x + Config.MARKER_NEEDLE_X,
      locationY: y + Config.MARKER_NEEDLE_Y,
    };

    this.flowerService.updateFlower(point.id, preparedPoint).subscribe(({ locationX, locationY }) => {
      const index = this.flowers.findIndex(({ id }) => id === point.id);

      if (index > -1) {
        this.flowers[index].locationX = locationX - Config.MARKER_NEEDLE_X;
        this.flowers[index].locationY = locationY - Config.MARKER_NEEDLE_Y;
      }

      this.isLoadingComponent = false;
    });
  }

  onHoverStart(flower: Flower): void {
    this.flowerService.startHover(flower.id);
  }

  onHoverEnd(): void {
    this.flowerService.endHover();
  }

  openUploadMapModal(): void {
    const uploadMapModal = this.modalService.open(UploadMapModalComponent, {
      backdropClass: 'light-blue-backdrop',
      centered: true
    });

    uploadMapModal.componentInstance.uploadedFloorMapChange.subscribe((receivedImage) => {
      this.floorImage = receivedImage.imageUrl;

      // Reset points
      this.flowers.map((flower) => {
        flower.locationX = 0;
        flower.locationY = 0;
      });
    });

    uploadMapModal.componentInstance.floor = this.floor;
  }
}
