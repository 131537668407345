import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProblemService {


  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
  ) {}

  getProblemImage(problemId: string) {
    return this.httpClient.get(this.apiService.url('problems/' + problemId + '/photo'));
  }
}
