import { Config } from '../../config';

export class Notification {
  public static NEVER: number = -1;
  public static DEFAULT: number = Config.NOTIFICATION_DEFAULT_TIMEOUT;

  constructor(
    public id: string,
    public type: NotificationType,
    public message: string,
    public timeout: number,
  ) { }
}

export enum NotificationType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}
