import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { PaginatedResult } from '../interfaces/pagination';
import { getFilterDayTasksParams } from './helpers/filterHelper';
import { getPaginatedResult } from './helpers/paginationHelper';
import { SearchDayTaskState } from '../interfaces/SearchDayTaskState';
import { DayTask } from '../models/dayTask';
import { debounceTime, delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DayTaskService {
  private _searchBuilding$ = new Subject<void>();

  public _state: SearchDayTaskState = {
    page: 1,
    itemsPerPage: 8,
    'flower.floor.building.id': null,
    'date[after]': null,
    'date[before]': null,
    'updatedBy.id': null,
    sortBy: '',
    sortByDirection: '',
    status: '',
  };

  public _stateForPdf: SearchDayTaskState = {
    page: 1,
    itemsPerPage: 999999999,
    'flower.floor.building.id': null,
    'date[after]': null,
    'date[before]': null,
    'updatedBy.id': null,
    sortBy: '',
    sortByDirection: '',
    status: '',
  };

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService
  ) {
    this._searchBuilding$.pipe(
      tap(x => {
        debounceTime(200),
          this.getDayTasks(),
          delay(200);
      })
    ).subscribe();
  }

  getDayTasks(stateForPdf: boolean = false): Observable<PaginatedResult<any>> {
    const params = getFilterDayTasksParams(stateForPdf ? this._stateForPdf : this._state);
    return getPaginatedResult<DayTask>(this.apiService.url('day_tasks'), params, this.httpClient);
  }
}
