<div
  class="overlay"
  [style.position]="position"
  [style.transform]="transform"
  [style.top]="top"
  [style.right]="right"
  [style.bottom]="bottom"
  [style.left]="left">
  <fa-icon [icon]="faCircleNotch" class="fa-spin"></fa-icon>
  <span>{{ 'COMPONENTS.DASHBOARD.LOADING_SMALL.LOADING' | translate }}</span>
</div>
