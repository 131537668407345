import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkboxes-days',
  templateUrl: './checkboxes-days.component.html',
  styleUrls: ['./checkboxes-days.component.scss']
})
export class CheckboxesDaysComponent implements OnInit {
  @Input() id: number;

  constructor() { }

  ngOnInit(): void {
  }

}
