import { Component, Input } from '@angular/core';
import { faEye, faPlus, faUpload, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() title: string;
  @Input() class: string;
  @Input() icon: 'add' | 'remove' | 'upload' | 'eye' | 'expand' | 'compress';
  @Input() disabled: any;

  public faPlus = faPlus;
  public faTrash = faTrash;
  public faUpload = faUpload;
  public faEye = faEye;
  public faExpand = faExpand;
  public faCompress = faCompress;
}
