import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputModule } from '../../input/input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { EditUserWorkScheduleModalComponent } from './edit-user-work-schedule-modal.component';
import { LoadingContentModule } from '../../../loading-content/loading-content.module';
import { LoadingSmallModule } from '../../loading-small/loading-small.module';


@NgModule({
  declarations: [EditUserWorkScheduleModalComponent],
  exports: [EditUserWorkScheduleModalComponent],
  imports: [
    TranslateModule,
    CommonModule,
    ButtonModule,
    FontAwesomeModule,
    InputModule,
    ReactiveFormsModule,
    LoadingContentModule,
    LoadingSmallModule
  ]
})
export class EditUserWorkScheduleModalModule { }
