import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-floor-modal',
  templateUrl: './create-floor-modal.component.html',
  styleUrls: ['./create-floor-modal.component.scss']
})
export class CreateFloorModalComponent implements OnInit {
  public faTimes = faTimes;
  public floorCreateForm: FormGroup;
  @Output() addedFloorChange = new EventEmitter<any>();

  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.floorCreateForm = new FormGroup({
      description: new FormControl('', [Validators.required]),
    });
  }

  saveFloor(): void {
    this.addedFloorChange.emit({
      form: this.floorCreateForm
    });
    this.modal.dismiss();
  }

}
