import { Component, OnInit } from '@angular/core';
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss']
})
export class WelcomePageComponent implements OnInit {
  public faUser = faUser;
  public faBuilding = faBuilding;

  constructor() { }

  ngOnInit(): void {
  }

}
