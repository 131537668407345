<div class="notifications">
  <div *ngFor="let notification of notificationsService.notifications" @notificationAnimation class="notification" [class]="notification.type">
    <div [ngSwitch]="notification.type" class="icon">
      <fa-icon *ngSwitchCase="NotificationType.success" [icon]="faCheck"></fa-icon>
      <fa-icon *ngSwitchCase="NotificationType.warning" [icon]="faExclamationCircle"></fa-icon>
      <fa-icon *ngSwitchCase="NotificationType.error" [icon]="faTimesCircle"></fa-icon>
      <fa-icon *ngSwitchCase="NotificationType.info" [icon]="faInfoCircle"></fa-icon>
      <fa-icon *ngSwitchDefault [icon]="faStickyNote"></fa-icon>
    </div>
    <div>{{ notification.message | translate }}</div>
    <div (click)="close(notification)" class="close">
      <fa-icon [icon]="faTimes"></fa-icon>
    </div>
  </div>
</div>
