<div class="modal-body">
  <button
    (click)="modal.dismiss('Cancel click')"
    type="button"
    class="close-modal"
    aria-describedby="modal-title">
    <fa-icon class="close-icon" [icon]="faTimes"></fa-icon>
  </button>
  <h3 class="font-weight-bold text-center">{{ 'COMPONENTS.DASHBOARD.SHOW_PROBLEMS_IN_TASK_MODAL.PROBLEMS_LIST' | translate }}</h3>

  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ problem.description }}</h5>
      <p class="card-date"><strong>{{ 'COMPONENTS.DASHBOARD.SHOW_PROBLEMS_IN_TASK_MODAL.CREATED_AT' | translate }}</strong> {{ problem.createdAt | date:'dd.MM.yyyy HH:mm' }}</p>

      <app-loading-content [loading]="isLoadingComponent">
<!--        <a [href]="image | safeHtml" target="_blank">-->
          <img [src]="image | safeHtml" alt="{{ 'COMPONENTS.DASHBOARD.SHOW_PROBLEMS_IN_TASK_MODAL.PROBLEM_IMAGE' | translate }}" class="img-fluid">
<!--        </a>-->
      </app-loading-content>
    </div>
  </div>

</div>
