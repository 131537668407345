<div class="row">
  <div class="col-12">
    <div class="breadcrumbs-header">
      <ul>
        <li *ngFor="let breadcrumb of (breadcrumbService.breadcrumbs$ | async)">{{ breadcrumb.label | translate }}</li>
      </ul>
      <a *ngIf="backButton" (click)="goBack()" class="go-back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
        {{ 'COMPONENTS.DASHBOARD.USERS_DETAILS.GO_BACK' | translate }}
      </a>
    </div>
  </div>
</div>
