import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { CreateFloorModalComponent } from './create-floor-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputModule } from '../../input/input.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [CreateFloorModalComponent],
  exports: [CreateFloorModalComponent],
  imports: [
    TranslateModule,
    CommonModule,
    ButtonModule,
    FontAwesomeModule,
    InputModule,
    ReactiveFormsModule,
  ]
})
export class CreateFloorModalModule { }
