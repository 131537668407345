<div class="container" id="login-container">
  <div class="row">
    <div class="col-12 col-lg-8 offset-lg-2">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-7">
              <form [formGroup]="forgotPasswordForm" class="h-100 d-flex flex-column justify-content-between">
                <div class="row mb-60">
                  <div class="col-12">
                    <img src="assets/images/calla2.png" class="img-fluid logo">
                  </div>
                </div>
                <div class="row mb-40">
                  <div class="col-12">
                    <div class="mb-20">
                      <h6>{{ 'PAGES.FORGOT_PASSWORD.ENTER_EMAIL' | translate }}</h6>
                      <p>{{ 'PAGES.FORGOT_PASSWORD.WE_WILL_SEND_INSTRUCTIONS' | translate }}</p>
                    </div>
                    <div class="mb-20">
                      <app-input
                        [class]="'form-control'"
                        [placeholder]="'PAGES.FORGOT_PASSWORD.EMAIL' | translate"
                        formControlName="email"
                        name="email"
                        type="text">
                      </app-input>
                    </div>
                  </div>
                </div>
                <div class="row mb-10">
                  <div class="col-12">
                    <app-button
                      (click)="rememberPassword()"
                      [disabled]="forgotPasswordForm.invalid"
                      [title]="'PAGES.FORGOT_PASSWORD.REMIND_PASSWORD' | translate"
                      [class]="'btn btn-lg btn-primary font-weight-bold login-btn'">
                    </app-button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <app-button
                      [routerLink]="['/login']"
                      [title]="'PAGES.FORGOT_PASSWORD.LOGIN' | translate"
                      [class]="'btn btn-primary font-weight-bold login-btn'">
                    </app-button>
                    <app-button
                      [routerLink]="['/change-password']"
                      [title]="'PAGES.FORGOT_PASSWORD.I_HAVE_CODE' | translate"
                      [class]="'btn btn-primary font-weight-bold i-have-a-code'">
                    </app-button>
                  </div>
                </div>
              </form>
            </div>
            <div class="d-none d-md-flex col-md-5">
              <img src="assets/images/loginbg.png" class="img-fluid flower">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
