import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { InputModule } from '../../input/input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateEditPointInFloorModalComponent } from './create-edit-point-in-floor-modal.component';
import { LoadingSmallModule } from '../../loading-small/loading-small.module';
import { LoadingContentModule } from '../../../loading-content/loading-content.module';
import { AlertModule } from '../../alert/alert.module';

@NgModule({
  declarations: [CreateEditPointInFloorModalComponent],
  exports: [CreateEditPointInFloorModalComponent],
  bootstrap: [CreateEditPointInFloorModalComponent],
  imports: [
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    ButtonModule,
    InputModule,
    ReactiveFormsModule,
    LoadingSmallModule,
    LoadingContentModule,
    AlertModule
  ]
})
export class CreateEditPointInFloorModalModule { }
