<div class="card card-details-editable mt-5 mt-md-0">
  <div class="card-body">
    <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.USERS_WORK_SCHEDULE.WORK_SCHEDULE' | translate}}</h5>
    <h3 class="card-title-secondary">{{ 'COMPONENTS.DASHBOARD.USERS_WORK_SCHEDULE.BUILDING_NAME' | translate}}</h3>
    <div class="card-content mt-20">
      <ul class="items-list" *ngIf="userBuildings?.length > 0; else noBuildings">
        <li *ngFor="let userBuilding of userBuildings">
          <div class="li-content" (click)="listItemAction(userBuilding)">
            <div class="title">
              {{userBuilding.building.name}}
            </div>
            <fa-icon class="arrow-icon" [icon]="editable ? faEdit : faAngleRight"></fa-icon>
          </div>
        </li>
      </ul>
      <ng-template #noBuildings>
        <app-alert
          [title]="'COMPONENTS.DASHBOARD.USERS_WORK_SCHEDULE.NO_BUILDINGS' | translate"
          [class]="'alert-warning mt-20 mb-20'">
        </app-alert>
      </ng-template>
      <fa-icon class="edit-cog" [class.edit-cog--active]="editable" [icon]="faCog" (click)="toggleEditable()"></fa-icon>
    </div>
    <app-loading-small
      *ngIf="isLoadingComponent"
      position="absolute"
      bottom="12px"
      right="30px">
    </app-loading-small>
  </div>
</div>
