<div class="modal-body">
  <button
    (click)="modal.dismiss('Cancel click')"
    type="button"
    class="close-modal"
    aria-describedby="modal-title">
    <fa-icon class="close-icon" [icon]="faTimes"></fa-icon>
  </button>
  <h3 class="font-weight-bold text-center">{{ 'COMPONENTS.DASHBOARD.CONFIRM_DELETE_MODAL.REMOVE_QUESTION' | translate }}</h3>
  <div class="actions">
    <app-button (click)="modal.close('Ok click')"
                [title]="'COMPONENTS.DASHBOARD.CONFIRM_DELETE_MODAL.DELETE_SELECTED' | translate"
                [class]="'btn btn-outline-danger font-weight-bold mr-20'"
                icon="remove">
    </app-button>
    <app-button (click)="modal.dismiss('Cancel click')"
                [title]="'COMPONENTS.DASHBOARD.CONFIRM_DELETE_MODAL.CANCEL' | translate"
                [class]="'btn btn-outline-dark font-weight-bold'">
    </app-button>
  </div>
</div>
