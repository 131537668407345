import { NgModule } from '@angular/core';
import { InputWithIconComponent } from './input-with-icon.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [InputWithIconComponent],
  exports: [InputWithIconComponent],
  imports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FontAwesomeModule,
  ]
})
export class InputWithIconModule { }
