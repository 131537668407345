<div class="modal-body">
  <button
    (click)="modal.dismiss('Cancel click')"
    type="button"
    class="close-modal"
    aria-describedby="modal-title">
    <fa-icon class="close-icon" [icon]="faTimes"></fa-icon>
  </button>
  <h3 class="font-weight-bold text-center">{{ 'COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.UPLOAD_TITLE' | translate }}</h3>

  <app-alert
    [title]="'<strong>' + ('COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.WARNING' | translate) + '</strong> ' + ('COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.WARNING_MESSAGE' | translate)"
    [class]="'alert-warning alert-warning text-center'">
  </app-alert>

  <app-alert
    *ngIf="typeError"
    [title]="('COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.ERROR_FILE_TYPE' | translate) + ' ' + allowedFormats.join(', ') + '.'"
    [class]="'alert-danger alert-warning text-center'">
  </app-alert>

  <app-alert
    *ngIf="sizeError"
    [title]="('COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.ERROR_FILE_SIZE' | translate) + ' ' + maxFileSize / 1048576 + 'MB'"
    [class]="'alert-danger alert-warning text-center'">
  </app-alert>

  <app-alert
    *ngIf="minResolutionError"
    [title]="('COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.ERROR_FILE_MIN_RESOLUTION' | translate) + ' ' + minImageResolutionWidth + 'px x ' + minImageResolutionHeight + 'px.'"
    [class]="'alert-danger alert-warning text-center'">
  </app-alert>

  <app-alert
    *ngIf="maxResolutionError"
    [title]="('COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.ERROR_FILE_MAX_RESOLUTION' | translate) + ' ' + maxImageResolutionWidth + 'px x ' + maxImageResolutionHeight + 'px.'"
    [class]="'alert-danger alert-warning text-center'">
  </app-alert>

  <div class="custom-file">
    <input type="file" class="custom-file-input" id="mapFile" accept="image/*" #mapFile (change)="onChange($event)">
    <label class="custom-file-label" for="mapFile">
      {{ file !== null ? (('COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.SELECTED_FILE' | translate) + ': ' + (file.name | truncateText:  [18])) : ('COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.BROWSE_FILES' | translate) }}
    </label>
  </div>

  <div class="d-flex justify-content-end mt-20">
    <app-button
      *ngIf="imageUrl"
      (click)="checkUploadedMap()"
      [title]="'COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.CHECK_SENT_MAP' | translate"
      [class]="'btn btn-primary font-weight-bold btn-sm mr-20'"
      icon="eye">
    </app-button>

    <app-button
      (click)="onConfirmUploadModal()"
      [title]="'COMPONENTS.DASHBOARD.UPLOAD_MAP_MODAL.UPLOAD_MAP' | translate"
      [class]="'btn btn-primary font-weight-bold btn-sm'"
      [disabled]="!file"
      icon="upload">
    </app-button>
  </div>


  <div class="position-relative">
    <app-loading-small
      *ngIf="isLoadingComponent"
      position="absolute"
      bottom="0px"
      left="10px">
    </app-loading-small>
  </div>
</div>
