import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../services/notifications.service';
import { Notification, NotificationType } from '../../interfaces/notification';
import { notificationAnimation } from '../../animations/notification.animation';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [notificationAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public readonly NotificationType = NotificationType;
  private subscription$: Subscription;

  public faCheck = faCheck;
  public faExclamationCircle = faExclamationCircle;
  public faTimesCircle = faTimesCircle;
  public faInfoCircle = faInfoCircle;
  public faStickyNote = faStickyNote;
  public faTimes = faTimes;

  constructor(
    public notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    this.subscription$ = this.notificationsService
      .getObservable$()
      .subscribe(() => this.cdr.detectChanges());
  }

  public ngOnDestroy(): void {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

  public close(notification: Notification): void {
    this.notificationsService.close(notification);
  }
}
