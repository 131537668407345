import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { BuildingEmployee } from '../models/buildingEmployee';
import { getFilterAssignedEmployeesParams, getFilterUsersParams } from './helpers/filterHelper';
import { getPaginatedResult } from './helpers/paginationHelper';
import { PaginatedResult } from '../interfaces/pagination';
import { Flower } from '../models/flower';
import { Building } from '../models/building';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService,
  ) {}

  getEmployees(data): Observable<PaginatedResult<any>> {
    const params = getFilterAssignedEmployeesParams(data);
    return getPaginatedResult(this.apiService.url('users'), params, this.httpClient);
  }

  createEmployeeBuilding(data): Observable<BuildingEmployee> {
    return this.httpClient.post<BuildingEmployee>(this.apiService.url('user_buildings'), {
      ...data,
      status: 1,
    })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.BUILDING.NOTIFICATIONS.EMPLOYEE_ADDED_TO_BUILDING');
        })
      );
  }

  updateEmployeeBuilding(buildingId, data): Observable<BuildingEmployee> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');

    return this.httpClient.patch<BuildingEmployee>(this.apiService.url('user_buildings/' + buildingId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.BUILDING.NOTIFICATIONS.EMPLOYEE_UPDATED_IN_BUILDING');
        })
      );
  }

  getUserBuildings(data) {
    const params = getFilterAssignedEmployeesParams(data);
    return getPaginatedResult(this.apiService.url('user_buildings'), params, this.httpClient);
  }

  getUserBuilding(userBuildingId: number): Observable<BuildingEmployee> {
    return this.httpClient.get<BuildingEmployee>(this.apiService.url('user_buildings/' + userBuildingId));
  }

  removeEmployeeBuilding(buildingEmployeeId): Observable<BuildingEmployee> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');

    return this.httpClient.delete<BuildingEmployee>(this.apiService.url('user_buildings/' + buildingEmployeeId), {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.FLOOR.NOTIFICATIONS.EMPLOYEE_DELETED');
        })
      );
  }

}
