import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmDeleteModalComponent } from './confirm-delete-modal.component';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [ConfirmDeleteModalComponent],
  exports: [ConfirmDeleteModalComponent],
  imports: [
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    ButtonModule
  ]
})
export class ConfirmDeleteModalModule { }
