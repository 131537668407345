import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { ShowProblemsInTaskModalComponent } from './show-problems-in-task-modal.component';
import { SafeHtmlPipePipe } from '../../../../pipes/safe-html-pipe.pipe';
import { LoadingContentModule } from '../../../loading-content/loading-content.module';


@NgModule({
  declarations: [ShowProblemsInTaskModalComponent, SafeHtmlPipePipe],
  exports: [ShowProblemsInTaskModalComponent],
  imports: [
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    ButtonModule,
    LoadingContentModule
  ]
})
export class ShowProblemsInTaskModalModule { }
