import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { DefaultTask } from '../models/defaultTask';

@Injectable({
  providedIn: 'root',
})
export class DefaultTaskService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService
  ) {}

  getDefaultTasks(): Observable<DefaultTask[]> {
    return this.httpClient
      .get(this.apiService.url(`default_tasks`))
      .pipe(map((data) => data['hydra:member']
        .map(({ id, description }) => ({
          id,
          description,
        } as DefaultTask))));
  }

  createDefaultTask(data): Observable<DefaultTask> {
    return this.httpClient.post<DefaultTask>(this.apiService.url('default_tasks'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.DEFAULT_TASK.NOTIFICATIONS.DEFAULT_TASK_CREATED');
        })
      );
  }

  removeDefaultTask(defaultTaskId: number): Observable<DefaultTask> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');

    return this.httpClient.delete<DefaultTask>(this.apiService.url('default_tasks/' + defaultTaskId), {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.DEFAULT_TASK.NOTIFICATIONS.DEFAULT_TASK_DELETED');
        })
      );
  }

  updateDefaultTask(defaultTaskId, data): Observable<DefaultTask> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');
    return this.httpClient.patch<DefaultTask>(this.apiService.url('default_tasks/' + defaultTaskId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.DEFAULT_TASK.NOTIFICATIONS.DEFAULT_TASK_UPDATED');
        })
      );
  }
}
