import { Component, OnInit } from '@angular/core';
import { User } from '../../../../models/user';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Config } from '../../../../../config';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';

@Component({
  selector: 'app-users-general-information',
  templateUrl: './users-general-information.component.html',
  styleUrls: ['./users-general-information.component.scss']
})
export class UsersGeneralInformationComponent implements OnInit {
  public faCog = faCog;
  public user: User;
  public editable: boolean = false;
  public userDetailsForm: FormGroup;
  public createdAt: string;
  public isLoadingComponent: boolean = false;
  public roleAdmin = Config.ROLE_ADMIN;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.user = data.user;
    });

    this.userDetailsForm = new FormGroup({
      userFullName: new FormControl(this.user.userFullName, [Validators.required]),
      email: new FormControl(this.user.email, [Validators.required, Validators.email]),
      isAdmin: new FormControl(this.user.roles.includes(this.roleAdmin)),
    });
  }

  toggleEditable() {
    this.editable = !this.editable;
  }

  save() {
    if (this.userDetailsForm.invalid) {
      return;
    }
    this.isLoadingComponent = true;

    const preparedData = {
      userFullName: this.userDetailsForm.value.userFullName,
      email: this.userDetailsForm.value.email,
      roles: this.userDetailsForm.value.isAdmin ? [this.roleAdmin] : [],
    };

    this.userService.updateUser(this.user.id, preparedData).pipe(finalize(() => {
      this.isLoadingComponent = false;
      this.breadcrumbService.lastPart = preparedData.userFullName;
    })).subscribe();
  }
}
