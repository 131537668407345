import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';
import { BuildingsListOfTheFloorsComponent } from './buildings-list-of-the-floors.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../../../ui-components/button/button.module';
import { CreateFloorModalModule } from '../../../ui-components/modals/create-floor-modal/create-floor-modal.module';
import { AlertModule } from '../../../ui-components/alert/alert.module';


@NgModule({
  declarations: [BuildingsListOfTheFloorsComponent],
  exports: [BuildingsListOfTheFloorsComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    LoadingSmallModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule,
    ButtonModule,
    CreateFloorModalModule,
    AlertModule
  ]
})
export class BuildingsListOfTheFloorsModule { }
