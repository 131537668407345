import { NgModule } from '@angular/core';
import { BuildingsIndexPageComponent } from './buildings-index.page';
import { BreadcrumbModule } from '../../../../components/ui-components/breadcrumb/breadcrumb.module';
import { BuildingsTableModule } from '../../../../components/dashboard/buildings/buildings-table/buildings-table.module';


@NgModule({
  declarations: [BuildingsIndexPageComponent],
  exports: [BuildingsIndexPageComponent],
  imports: [
    BreadcrumbModule,
    BuildingsTableModule
  ]
})
export class BuildingsIndexPageModule {
}
