<div class="container pb-60">
  <app-breadcrumb [backButton]="true"></app-breadcrumb>
  <div class="row mt-60">
    <div class="col-12 col-md-5">
      <!--Informacje ogólne-->
      <app-buildings-general-information></app-buildings-general-information>

      <!--Przypisani pracownicy-->
      <app-buildings-assigned-employees></app-buildings-assigned-employees>
    </div>
    <div class="col-12 col-md-7">
      <!--Rozpiska pięter-->
      <app-buildings-list-of-the-floors></app-buildings-list-of-the-floors>
    </div>
  </div>
</div>
