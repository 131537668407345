import { Component, OnInit } from '@angular/core';
import { DefaultTask } from '../../../../models/defaultTask';
import { DefaultTaskService } from '../../../../services/default_task.service';
import { faCog, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateEditDefaultTaskModalComponent } from '../../../ui-components/modals/create-edit-default-task-modal/create-edit-default-task-modal.component';

@Component({
  selector: 'app-settings-items-default-tasks',
  templateUrl: './settings-items-default-tasks.component.html',
  styleUrls: ['./settings-items-default-tasks.component.scss']
})
export class SettingsItemsDefaultTasksComponent implements OnInit {
  public defaultTasks: DefaultTask[];
  public isLoadingComponent: boolean = false;
  public editable: boolean = false;
  public faTimesCircle = faTimesCircle;
  public faCog = faCog;

  constructor(
    private defaultTaskService: DefaultTaskService,
    private modalService: NgbModal,

  ) { }

  ngOnInit() {
    this.isLoadingComponent = true;
    return this.defaultTaskService.getDefaultTasks().subscribe(response => {
      this.defaultTasks = response;
      this.isLoadingComponent = false;
    });
  }

  createEditDefaultTaskModal(defaultTask?: DefaultTask): void {
    const createDefaultTaskModal = this.modalService.open(CreateEditDefaultTaskModalComponent, {
      backdropClass: 'light-blue-backdrop',
      centered: true
    });

    createDefaultTaskModal.componentInstance.defaultTasks = this.defaultTasks;

    if (defaultTask) {
      createDefaultTaskModal.componentInstance.defaultTask = defaultTask;
    }

    // Add
    createDefaultTaskModal.componentInstance.addedDefaultTaskChange.subscribe((receivedDefaultTask) => {
      this.isLoadingComponent = true;

      const preparedDefaultTask: DefaultTask = {
        description: receivedDefaultTask.description
      };

      this.defaultTaskService.createDefaultTask(preparedDefaultTask).subscribe((response) => {
        const preparedToPushDefaultTask = {
          id: response.id,
          description: response.description
        };

        this.defaultTasks.push(preparedToPushDefaultTask);
        this.isLoadingComponent = false;
      });
    });

    // Update
    createDefaultTaskModal.componentInstance.updatedDefaultTaskChange.subscribe((receivedDefaultTask) => {
      this.isLoadingComponent = true;

      const preparedDefaultTask: DefaultTask = {
        id: receivedDefaultTask.id,
        description: receivedDefaultTask.description
      };

      this.defaultTaskService.updateDefaultTask(preparedDefaultTask.id, preparedDefaultTask).subscribe((response) => {
        const foundDefaultTask = this.defaultTasks.find(item => item.id === response.id);

        if (foundDefaultTask) { foundDefaultTask.description = response.description; }
        this.isLoadingComponent = false;
      });
    });
  }

  removeDefaultTask(defaultTaskId): void {
    this.isLoadingComponent = true;
    this.defaultTaskService.removeDefaultTask(defaultTaskId).subscribe(() => {
      this.removeDefaultTaskFromArray(defaultTaskId);
      this.isLoadingComponent = false;
    });
  }

  toggleEditable() {
    this.editable = !this.editable;
  }

  private removeDefaultTaskFromArray(defaultTaskId) {
    const removeIndex = this.defaultTasks.map(item => item.id).indexOf(defaultTaskId);
    this.defaultTasks.splice(removeIndex, 1);
  }
}
