<div class="modal-body">
  <button
    (click)="modal.dismiss('Cancel click')"
    type="button"
    class="close-modal"
    aria-describedby="modal-title">
    <fa-icon class="close-icon" [icon]="faTimes"></fa-icon>
  </button>
  <h3 class="font-weight-bold text-center">{{ 'COMPONENTS.DASHBOARD.CREATE_FLOOR_MODAL.TITLE' | translate }}</h3>

  <form [formGroup]="floorCreateForm">
    <app-input
      [class]="'form-control'"
      [label]="'COMPONENTS.DASHBOARD.CREATE_FLOOR_MODAL.FLOOR_NAME' | translate"
      [placeholder]="'COMPONENTS.DASHBOARD.CREATE_FLOOR_MODAL.ENTER_FLOOR_NAME' | translate"
      formControlName="description"
      name="description"
      type="text">
    </app-input>
  </form>

  <div class="d-flex justify-content-end mt-20">
    <app-button (click)="saveFloor()"
                [title]="'COMPONENTS.DASHBOARD.CREATE_FLOOR_MODAL.ADD_FLOOR' | translate"
                [class]="'btn btn-primary font-weight-bold btn-sm mr-20'">
    </app-button>
  </div>
</div>
