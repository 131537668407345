<div class="card card-details-editable mt-5">
  <div class="card-body">
    <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_DETAILS.ASSIGNED_EMPLOYEES' | translate }}</h5>
    <div class="card-content">

      <div *ngIf="assignedEmployeesForm.get('items')['controls']?.length > 0; else noAssignedEmployees">
        <div class="row mb-20">
          <div class="col-6">
            <h6 class="font-weight-bold">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_ASSIGNED_EMPLOYEES.NAME_SURNAME' | translate }}</h6>
          </div>
          <div class="col-6">
            <h6 class="font-weight-bold">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_ASSIGNED_EMPLOYEES.ASSIGNED_DAYS' | translate }}</h6>
          </div>
        </div>

        <form [formGroup]="assignedEmployeesForm">
          <div formArrayName="items" *ngFor="let item of assignedEmployeesForm.get('items')['controls']; let id = index">
            <div class="row mb-20" [formGroupName]="id">
              <div class="col-6 d-flex align-items-center">
                <div class="form-group position-relative mb-0">
                  <select [attr.disabled]="!editable ? '' : null" (change)="changeSelectedUser($event.target.value)" formControlName="userId" class="form-control">
                    <option value="default" [disabled]="true">{{defaultSelectValue}}</option>
                    <option *ngFor="let employee of employeesFor(item); let i = index" value="{{employee.id}}" [disabled]="employee.disabled">
                      {{employee.userFullName}}
                    </option>
                  </select>
                  <fa-icon *ngIf="this.editable" (click)="removeEmployeeBuilding(id, item.value.id)" class="remove-icon" [icon]="faTimesCircle"></fa-icon>
                </div>
              </div>

              <div class="col-6 d-flex align-items-center">
                <div class="checkboxes-days">
                  <div class="form-group" *ngFor="let day of ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']">
                    <input [attr.disabled]="!editable || item.value.userId === 'default' ? '' : null" [formControlName]="day" type="checkbox" [id]="day.toLowerCase() + '-' + id">
                    <label [ngClass]="{'inactive': !editable || item.value.userId === 'default'}" [for]="day.toLowerCase() + '-' + id">{{ ('WEEK_DAYS.' + day + '.SHORT' ) | translate }}</label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>

      <ng-template #noAssignedEmployees>
        <app-alert
          [title]="'COMPONENTS.DASHBOARD.BUILDINGS_ASSIGNED_EMPLOYEES.NO_EMPLOYEES' | translate"
          [class]="'alert-warning mt-20 mb-20'">
        </app-alert>
      </ng-template>

      <div class="row" *ngIf="editable">
        <div class="col-12">
          <app-button
            (click)="addEmployee()"
            [title]="'COMPONENTS.DASHBOARD.BUILDINGS_ASSIGNED_EMPLOYEES.ADD_EMPLOYEE' | translate"
            [class]="'btn btn-sm font-weight-bold btn-primary padding-sm'"
            [disabled]="disableNewEmployeeButton"
            icon="add">
          </app-button>
        </div>
      </div>
    </div>
    <fa-icon class="edit-cog" [class.edit-cog--active]="editable" [icon]="faCog" (click)="toggleEditable()"></fa-icon>
    <app-loading-small
      *ngIf="isLoadingComponent"
      position="absolute"
      bottom="15px"
      right="30px">
    </app-loading-small>
  </div>
</div>
