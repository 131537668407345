import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';
import { finalize } from 'rxjs/operators';
import { ValidateEmail } from '../../validators/email.validator';


@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPageComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public codeForm: FormGroup;

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
  ) { }

  public ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, ValidateEmail]),
    });
  }

  public rememberPassword(): void {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.loadingService.start();
    this.authService
      .rememberPassword$(this.forgotPasswordForm.value)
      .pipe(finalize(() => {
        this.router.navigate(['/change-password']);
        this.loadingService.stop();
      })).subscribe();
  }
}
