<link rel="stylesheet" href="buildings-create.component.scss">
<div class="row mt-60">
  <div class="col-12">
    <div class="card card-details-editable">
      <div class="card-body">
        <app-loading-small
          *ngIf="isLoadingComponent"
          position="absolute"
          top="26px"
          right="34px">
        </app-loading-small>
        <h5 class="card-title">{{ 'COMPONENTS.DASHBOARD.BUILDINGS_CREATE.GENERAL_INFORMATION' | translate }}</h5>
        <div class="card-content">
          <form [formGroup]="buildingCreateForm">
            <app-input
              [class]="'form-control'"
              [label]="'COMPONENTS.DASHBOARD.BUILDINGS_CREATE.FORM.BUILDING_NAME_LABEL' | translate"
              [placeholder]="'COMPONENTS.DASHBOARD.BUILDINGS_CREATE.FORM.BUILDING_NAME_PLACEHOLDER' | translate"
              formControlName="name"
              name="name"
              type="text">
            </app-input>
            <app-input
              [class]="'form-control'"
              [label]="'COMPONENTS.DASHBOARD.BUILDINGS_CREATE.FORM.BUILDING_DESCRIPTION_LABEL' | translate"
              [placeholder]="'COMPONENTS.DASHBOARD.BUILDINGS_CREATE.FORM.BUILDING_DESCRIPTION_PLACEHOLDER' | translate"
              formControlName="description"
              name="description"
              type="text">
            </app-input>
            <app-input
              [class]="'form-control'"
              [label]="'COMPONENTS.DASHBOARD.BUILDINGS_CREATE.FORM.BUILDING_LOCATION_LABEL' | translate"
              [placeholder]="'COMPONENTS.DASHBOARD.BUILDINGS_CREATE.FORM.BUILDING_LOCATION_PLACEHOLDER' | translate"
              formControlName="location"
              name="location"
              type="text">
            </app-input>
            <app-button
              (click)="addBuilding()"
              [disabled]="buildingCreateForm.invalid"
              [title]="'COMPONENTS.DASHBOARD.BUILDINGS_CREATE.FORM.ADD' | translate"
              [class]="'btn btn-sm btn-primary font-weight-bold mt-10'">
            </app-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
