import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { getPaginatedResult } from './helpers/paginationHelper';
import { getFilterAssignedEmployeesParams, getFilterBuildingsParams } from './helpers/filterHelper';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, delay, map, tap } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { PaginatedResult } from '../interfaces/pagination';
import { SearchBuildingState } from '../interfaces/SearchBuildingState';
import { Building } from '../models/building';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  private _search$ = new Subject<void>();
  public _state: SearchBuildingState = {
    page: 1,
    itemsPerPage: 6,
    name: '',
    sortBy: '',
    sortByDirection: '',
    status: 1
  };
  public buildings: Building[];

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService,
  ) {
    this._search$.pipe(
      tap(x => {
        debounceTime(200),
          this.getBuildings(),
          delay(200);
      })
    ).subscribe();

    this._search$.next();
  }

  get searchTerm() {
    return this._state.name;
  }

  set searchTerm(name: string) {
    this._set({name});
  }

  private _set(patch: Partial<SearchBuildingState>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  getBuilding(buildingId: string): Observable<Building> {
    return this.httpClient.get<Building>(this.apiService.url('buildings/' + buildingId));
  }

  createBuilding(data): Observable<Building> {
    return this.httpClient.post<Building>(this.apiService.url('buildings'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.BUILDING.NOTIFICATIONS.BUILDING_ADDED');
        })
      );
  }

  updateBuilding(buildingId: number, data): Observable<Building> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');
    return this.httpClient.patch<Building>(this.apiService.url('buildings/' + buildingId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.BUILDING.NOTIFICATIONS.BUILDING_UPDATED');
        })
      );
  }

  getBuildings(): Observable<PaginatedResult<any>> {
    const params = getFilterBuildingsParams(this._state);
    return getPaginatedResult<Building>(this.apiService.url('buildings'), params, this.httpClient);
  }

  removeBuildings(ids: number[]) {
    const data = {
      items: ids,
      type: 'building'
    };

    return this.httpClient.post<Building>(this.apiService.url('bulk-delete'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.BUILDING.NOTIFICATIONS.BUILDING_DELETED');
        })
      );
  }
}
