import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { NotFoundInterceptor } from './interceptors/not-found.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule } from '@auth0/angular-jwt';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { LoadingModule } from './components/loading/loading.module';
import { NotificationsModule } from './components/notifications/notifications.module';
import { ChangePasswordModule } from './pages/change-password/change-password.module';
import { LoginModule } from './pages/login/login.module';
import { ForgotPasswordModule } from './pages/forgot-password/forgot-password.module';
import { NotFoundModule } from './pages/not-found/not-found.module';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbPopoverModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    JwtModule,
    DashboardModule,
    LoadingModule,
    NotificationsModule,
    ChangePasswordModule,
    LoginModule,
    ForgotPasswordModule,
    NotFoundModule,
    FormsModule,
    NgbPopoverModule,
    FontAwesomeModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotFoundInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
