import { NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [NotificationsComponent],
  exports: [NotificationsComponent],
  imports: [
    TranslateModule,
    FontAwesomeModule,
    CommonModule
  ]
})
export class NotificationsModule { }
