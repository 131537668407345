import { NgModule } from '@angular/core';
import { BreadcrumbModule } from '../../../../components/ui-components/breadcrumb/breadcrumb.module';
import { UsersCreatePageComponent } from './users-create.page';
import { UsersCreateModule } from '../../../../components/dashboard/users/users-create/users-create.module';


@NgModule({
  declarations: [UsersCreatePageComponent],
  exports: [UsersCreatePageComponent],
  imports: [
    BreadcrumbModule,
    UsersCreateModule
  ]
})
export class UsersCreatePageModule {
}
