import { HttpParams } from '@angular/common/http';
import { SearchUserState } from '../../interfaces/SearchUserState';
import { SearchBuildingState } from '../../interfaces/SearchBuildingState';
import { SearchFlowerState } from '../../interfaces/SearchFlowerState';
import { SearchDayTaskState } from '../../interfaces/SearchDayTaskState';
import { SearchDayReportState } from '../../interfaces/SearchDayReportState';
import { AssignedEmployeeState } from '../../interfaces/AssignedEmployeeState';
import { TaskState } from '../../interfaces/TaskState';

export function getFilterUsersParams(filters: SearchUserState) {
  let params = new HttpParams();
  if (filters.userFullName.length > 0){
    params = params.append('userFullName', filters.userFullName.toString());
  }

  if (filters.page){
    params = params.append('page', filters.page.toString());
  }

  if (filters.itemsPerPage){
    params = params.append('itemsPerPage', filters.itemsPerPage.toString());
  }

  if (filters.sortBy){
    params = params.append(filters.sortBy.toString(), filters.sortByDirection.toString());
  }

  if (filters.status === 0 || filters.status){
    params = params.append('status', filters.status.toString());
  }

  return params;
}

export function getFilterBuildingsParams(filters: SearchBuildingState) {
  let params = new HttpParams();
  if (filters.name.length > 0){
    params = params.append('name', filters.name.toString());
  }

  if (filters.page){
    params = params.append('page', filters.page.toString());
  }

  if (filters.itemsPerPage){
    params = params.append('itemsPerPage', filters.itemsPerPage.toString());
  }

  if (filters.sortBy){
    params = params.append(filters.sortBy.toString(), filters.sortByDirection.toString());
  }

  if (filters.status === 0 || filters.status){
    params = params.append('status', filters.status.toString());
  }

  return params;
}

export function getFilterDayTasksParams(filters: SearchDayTaskState) {
  let params = new HttpParams();

  if (filters.page){
    params = params.append('page', filters.page.toString());
  }

  if (filters.itemsPerPage){
    params = params.append('itemsPerPage', filters.itemsPerPage.toString());
  }

  if (filters['flower.floor.building.id']){
    params = params.append('flower.floor.building.id', filters['flower.floor.building.id'].toString());
  }

  if (filters['updatedBy.id']){
    params = params.append('updatedBy.id', filters['updatedBy.id'].toString());
  }

  if (filters['date[after]']){
    params = params.append('date[after]', filters['date[after]'].toString());
  }

  if (filters['date[before]']){
    params = params.append('date[before]', filters['date[before]'].toString());
  }

  if (filters['date[strictly_after]']){
    params = params.append('date[strictly_after]', filters['date[strictly_after]'].toString());
  }

  if (filters['date[strictly_before]']){
    params = params.append('date[strictly_before]', filters['date[strictly_before]'].toString());
  }

  if (filters.sortBy){
    params = params.append(filters.sortBy.toString(), filters.sortByDirection.toString());
  }

  if (filters.status === 0 || filters.status){
    params = params.append('status', filters.status.toString());
  }

  return params;
}

export function getFilterDayReportsParams(filters: SearchDayReportState) {
  let params = new HttpParams();

  if (filters.page){
    params = params.append('page', filters.page.toString());
  }

  if (filters.itemsPerPage){
    params = params.append('itemsPerPage', filters.itemsPerPage.toString());
  }

  if (filters['createdAt[after]']){
    params = params.append('createdAt[after]', filters['createdAt[after]'].toString());
  }

  if (filters['createdAt[before]']){
    params = params.append('createdAt[before]', filters['createdAt[before]'].toString());
  }

  if (filters['createdBy.id']){
    params = params.append('createdBy.id', filters['createdBy.id'].toString());
  }

  if (filters['flower.floor.building.id']){
    params = params.append('flower.floor.building.id', filters['flower.floor.building.id'].toString());
  }

  if (filters['flower.id']){
    params = params.append('flower.id', filters['flower.id'].toString());
  }

  if (filters.status === 0 || filters.status){
    params = params.append('status', filters.status.toString());
  }

  return params;
}

export function getFilterAssignedEmployeesParams(filters: AssignedEmployeeState) {
  let params = new HttpParams();

  if (filters.page){
    params = params.append('page', filters.page.toString());
  }

  if (filters.itemsPerPage){
    params = params.append('itemsPerPage', filters.itemsPerPage.toString());
  }

  if (filters['building.id']){
    params = params.append('building.id', filters['building.id'].toString());
  }

  if (filters['worker.id']){
    params = params.append('worker.id', filters['worker.id'].toString());
  }

  return params;
}

export function getFilterFlowerParams(filters: SearchFlowerState) {
  let params = new HttpParams();

  if (filters.page){
    params = params.append('page', filters.page.toString());
  }

  if (filters.itemsPerPage){
    params = params.append('itemsPerPage', filters.itemsPerPage.toString());
  }

  if (filters.status){
    params = params.append('status', filters.status);
  }

  return params;
}

export function getFilterTaskParams(filters: TaskState) {
  let params = new HttpParams();

  if (filters.title){
    params = params.append('title', filters.title.toString());
  }

  if (filters.itemsPerPage){
    params = params.append('itemsPerPage', filters.itemsPerPage.toString());
  }

  if (filters['flower.id']){
    params = params.append('flower.id', filters['flower.id'].toString());
  }

  return params;
}
