import { NgModule } from '@angular/core';
import { BuildingsShowPageComponent } from './buildings-show.page';
import { BreadcrumbModule } from '../../../../components/ui-components/breadcrumb/breadcrumb.module';
import { BuildingsGeneralInformationModule } from '../../../../components/dashboard/buildings/buildings-general-information/buildings-general-information.module';
import { BuildingsAssignedEmployeesModule } from '../../../../components/dashboard/buildings/buildings-assigned-employees/buildings-assigned-employees.module';
import { BuildingsListOfTheFloorsModule } from '../../../../components/dashboard/buildings/buildings-list-of-the-floors/buildings-list-of-the-floors.module';


@NgModule({
  declarations: [BuildingsShowPageComponent],
  exports: [BuildingsShowPageComponent],
  imports: [
    BreadcrumbModule,
    BuildingsGeneralInformationModule,
    BuildingsAssignedEmployeesModule,
    BuildingsListOfTheFloorsModule
  ]
})
export class BuildingsShowPageModule { }
