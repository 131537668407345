import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '../../../../ui-components/input/input.module';
import { ButtonModule } from '../../../../ui-components/button/button.module';
import { LoadingSmallModule } from '../../../../ui-components/loading-small/loading-small.module';
import { BuildingsFloorsGeneralInformationComponent } from './buildings-floors-general-information.component';


@NgModule({
  declarations: [BuildingsFloorsGeneralInformationComponent],
  exports: [BuildingsFloorsGeneralInformationComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    InputModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    LoadingSmallModule,
    TranslateModule
  ]
})
export class BuildingsFloorsGeneralInformationModule { }
