import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Building } from '../../../../models/building';
import { BuildingService } from '../../../../services/building.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-buildings-create',
  templateUrl: './buildings-create.component.html',
  styleUrls: ['./buildings-create.component.scss']
})
export class BuildingsCreateComponent implements OnInit {
  public building: Building;
  public buildingCreateForm: FormGroup;
  public isLoadingComponent: boolean = false;

  constructor(
    private buildingService: BuildingService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.building = data.building;
    });

    this.buildingCreateForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      location: new FormControl('', Validators.required),
    });
  }

  addBuilding() {
    if (this.buildingCreateForm.invalid) {
      return this.buildingCreateForm.markAllAsTouched();
    }
    this.isLoadingComponent = true;
    this.buildingService
      .createBuilding({
        name: this.buildingCreateForm.value.name,
        description: this.buildingCreateForm.value.description,
        location: this.buildingCreateForm.value.location,
        status: 1,
      })
      .pipe(finalize(() => this.isLoadingComponent = false))
      .subscribe(() => {
        this.buildingCreateForm.reset();
        this.router.navigate(['/buildings']);
      });
  }
}
