import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { catchError, tap } from 'rxjs/operators';
import { Flower } from '../models/flower';
import { PaginatedResult } from '../interfaces/pagination';
import { getFilterFlowerParams } from './helpers/filterHelper';
import { getPaginatedResult } from './helpers/paginationHelper';
import { Building } from '../models/building';

@Injectable({
  providedIn: 'root',
})
export class FlowerService {
  private draggingFlowerSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public draggingFlower: Observable<number> = this.draggingFlowerSubject.asObservable();
  private hoverFlowerSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public hoverFlower: Observable<number> = this.hoverFlowerSubject.asObservable();

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService,
  ) {}

  getFlower(flowerId): Observable<Flower> {
    return this.httpClient.get<Flower>(this.apiService.url('flowers/' + flowerId));
  }

  updateFlower(flowerId, data): Observable<Flower> {
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');
    return this.httpClient.patch<Flower>(this.apiService.url('flowers/' + flowerId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.FLOWER.NOTIFICATIONS.OBJECT_UPDATED');
        })
      );
  }

  createFlower(data): Observable<Flower> {
    return this.httpClient.post<Flower>(this.apiService.url('flowers'), data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.FLOWER.NOTIFICATIONS.OBJECT_ADDED');
        })
      );
  }

  getFlowers(data): Observable<PaginatedResult<any>> {
    const params = getFilterFlowerParams(data);
    return getPaginatedResult(this.apiService.url('flowers'), params, this.httpClient);
  }

  removeFlower(flowerId: number) {
    const data = {
      status: 0
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');

    return this.httpClient.patch<Flower>(this.apiService.url('flowers/' + flowerId), data, {headers})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.FLOWER.NOTIFICATIONS.OBJECT_DELETED');
        })
      );
  }

  // Dragging
  onStartDrag(id: number) {
    this.draggingFlowerSubject.next(id);
  }

  onEndDrag() {
    this.draggingFlowerSubject.next(null);
  }

  // Hover
  startHover(id: number) {
    this.hoverFlowerSubject.next(id);
  }

  endHover() {
    this.hoverFlowerSubject.next(null);
  }
}
