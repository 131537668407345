import { Component } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  public faCircleNotch = faCircleNotch;

  constructor(public loadingService: LoadingService) {}
}
