import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loading-small',
  templateUrl: './loading-small.component.html',
  styleUrls: ['./loading-small.component.scss']
})
export class LoadingSmallComponent {
  public faCircleNotch = faCircleNotch;

  @Input() position: string = 'absolute';
  @Input() transform: string = null;
  @Input() top: string = null;
  @Input() right: string = null;
  @Input() bottom: string = null;
  @Input() left: string = null;
}
