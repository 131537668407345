import { NgModule } from '@angular/core';
import { BuildingsFloorsShowPageComponent } from './buildings-floors-show.page';
import { BreadcrumbModule } from '../../../../../components/ui-components/breadcrumb/breadcrumb.module';
import { ButtonModule } from '../../../../../components/ui-components/button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { BuildingsFloorsListOfPointsModule } from '../../../../../components/dashboard/buildings/buildings-floors/buildings-floors-list-of-points/buildings-floors-list-of-points.module';
import { BuildingsFloorsMapModule } from '../../../../../components/dashboard/buildings/buildings-floors/buildings-floors-map/buildings-floors-map.module';
import { BuildingsFloorsGeneralInformationModule } from '../../../../../components/dashboard/buildings/buildings-floors/buildings-floors-general-information/buildings-floors-general-information.module';


@NgModule({
  declarations: [BuildingsFloorsShowPageComponent],
  exports: [BuildingsFloorsShowPageComponent],
  imports: [
    TranslateModule,
    BreadcrumbModule,
    ButtonModule,
    BuildingsFloorsListOfPointsModule,
    BuildingsFloorsMapModule,
    BuildingsFloorsGeneralInformationModule
  ]
})
export class BuildingsFloorsShowPageModule { }
