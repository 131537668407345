<div class="container" id="login-container">
  <div class="row">
    <div class="col-12 col-lg-6 offset-lg-3">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-7">
              <form [formGroup]="changePasswordForm" class="h-100 d-flex flex-column justify-content-between">
                <div class="row mb-60">
                  <div class="col-12">
                    <img src="assets/images/calla2.png" class="img-fluid logo">
                  </div>
                </div>
                <div class="row mb-40">
                  <div class="col-12">
                    <div class="mb-20">
                      <h6>{{ 'PAGES.CHANGE_PASSWORD.DID_YOU_RECEIVE_THE_INSTRUCTION' | translate }}</h6>
                      <p>{{ 'PAGES.CHANGE_PASSWORD.COMPLETE_THE_FORM_BELOW' | translate }}</p>
                    </div>
                    <div class="mb-20">
                      <app-input
                        [class]="'form-control'"
                        [placeholder]="'PAGES.CHANGE_PASSWORD.EMAIL' | translate"
                        formControlName="email"
                        name="email"
                        type="text">
                      </app-input>
                    </div>
                    <div class="mb-20">
                      <app-input
                        [class]="'form-control'"
                        [placeholder]="'PAGES.CHANGE_PASSWORD.CODE' | translate"
                        formControlName="code"
                        name="code"
                        type="text">
                      </app-input>
                    </div>
                    <div class="mb-20">
                      <app-input
                        [class]="'form-control'"
                        [placeholder]="'PAGES.CHANGE_PASSWORD.NEW_PASSWORD' | translate"
                        formControlName="newPassword"
                        name="newPassword"
                        type="password">
                      </app-input>
                    </div>
                    <div class="mb-20">
                      <app-input
                        [class]="'form-control'"
                        [placeholder]="'PAGES.CHANGE_PASSWORD.REPEAT_NEW_PASSWORD' | translate"
                        formControlName="repeatPassword"
                        name="repeatPassword"
                        type="password">
                      </app-input>
                    </div>
                  </div>
                </div>
                <div class="row mb-10">
                  <div class="col-12">
                    <app-button
                      (click)="sendNewPassword()"
                      [disabled]="changePasswordForm.invalid"
                      [title]="'PAGES.CHANGE_PASSWORD.CHANGE_PASSWORD' | translate"
                      [class]="'btn btn-lg font-weight-bold btn-primary'">
                    </app-button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <app-button
                      [routerLink]="['/login']"
                      [title]="'PAGES.CHANGE_PASSWORD.LOGIN' | translate"
                      [class]="'btn btn-primary font-weight-bold'">
                    </app-button>
                  </div>
                </div>
              </form>
            </div>
            <div class="d-none d-md-flex col-md-5">
              <img src="assets/images/loginbg.png" class="img-fluid flower">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
