import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertModule } from '../../../ui-components/alert/alert.module';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';
import { UsersWorkScheduleComponent } from './users-work-schedule.component';
import { EditUserWorkScheduleModalModule } from '../../../ui-components/modals/edit-user-work-schedule-modal/edit-user-work-schedule-modal.module';


@NgModule({
  declarations: [UsersWorkScheduleComponent],
  exports: [UsersWorkScheduleComponent],
  providers: [DatePipe],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    AlertModule,
    LoadingSmallModule,
    EditUserWorkScheduleModalModule,
  ]
})
export class UsersWorkScheduleModule { }
