import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingSmallModule } from '../../../ui-components/loading-small/loading-small.module';
import { BuildingsAssignedEmployeesComponent } from './buildings-assigned-employees.component';
import { ButtonModule } from '../../../ui-components/button/button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxesDaysModule } from '../../../ui-components/checkboxes-days/checkboxes-days.module';
import { AlertModule } from '../../../ui-components/alert/alert.module';


@NgModule({
  declarations: [BuildingsAssignedEmployeesComponent],
  exports: [BuildingsAssignedEmployeesComponent],
  providers: [DatePipe],
  imports: [
    CommonModule,
    FontAwesomeModule,
    LoadingSmallModule,
    TranslateModule,
    ButtonModule,
    ReactiveFormsModule,
    CheckboxesDaysModule,
    AlertModule
  ]
})
export class BuildingsAssignedEmployeesModule { }
