export class Building {
  id: number;
  name: string;
  description: string;
  location: string;
  createdAt: string;
  flowers: any;
  floors: any;
  checked: boolean = true;
}
