import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BuildingService } from '../../../../../services/building.service';
import { ActivatedRoute } from '@angular/router';
import { faAngleLeft, faCog } from '@fortawesome/free-solid-svg-icons';
import { Floor } from '../../../../../models/floor';
import { FloorService } from '../../../../../services/floor.service';
import { BreadcrumbService } from '../../../../../services/breadcrumb.service';

@Component({
  selector: 'app-buildings-floors-general-information',
  templateUrl: './buildings-floors-general-information.component.html',
  styleUrls: ['./buildings-floors-general-information.component.scss']
})
export class BuildingsFloorsGeneralInformationComponent implements OnInit {
  public faAngleLeft = faAngleLeft;
  public faCog = faCog;
  public floor: Floor;
  public editable: boolean = false;
  public floorDetailsForm: FormGroup;
  public isLoadingComponent: boolean = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private buildingService: BuildingService,
    private route: ActivatedRoute,
    private floorService: FloorService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.floor = data.floor;

      this.floorDetailsForm = new FormGroup({
        description: new FormControl(this.floor.description, [Validators.required])
      });
    });
  }

  toggleEditable() {
    this.editable = !this.editable;
  }

  save() {
    if (this.floorDetailsForm.invalid) {
      return;
    }

    this.isLoadingComponent = true;

    const preparedFloor: Floor = {
      description: this.floorDetailsForm.value.description
    };

    this.floorService.updateFloor(this.floor.id, preparedFloor).subscribe((response) => {
      this.floor.description = response.description;
      this.isLoadingComponent = false;
      this.breadcrumbService.lastPart = this.floor.description;
    });
  }

}
