import { Component } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-buildings-index-page',
  templateUrl: './buildings-index.page.html',
  styleUrls: ['./buildings-index.page.scss'],
})
export class BuildingsIndexPageComponent {
  constructor(
    private authService: AuthService
  ) { }

  logout(){
    this.authService.logout();
  }
}
