import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiService: ApiService,
    private notificationsService: NotificationsService,
  ) {}

  uploadFloorMap(file): Observable<any> {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('name', file.name);

    return this.httpClient.post(this.apiService.url('media_objects'), formData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.notificationsService.error('SERVICES.AUTH.NOTIFICATIONS.GENERIC_ERROR');
          return throwError(error);
        }),
        tap(() => {
          this.notificationsService.success('SERVICES.UPLOAD_FILE.NOTIFICATIONS.MAP_UPLOADED');
        })
      );
  }
}
