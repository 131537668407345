import { Component, OnDestroy, OnInit } from '@angular/core';
import { faAngleLeft, faCog, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, mergeMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { EmployeeService } from '../../../../services/employee.service';
import { of, Subscription } from 'rxjs';
import { Employee } from '../../../../models/employee';
enum Days {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}
@Component({
  selector: 'app-buildings-assigned-employees',
  templateUrl: './buildings-assigned-employees.component.html',
  styleUrls: ['./buildings-assigned-employees.component.scss']
})
export class BuildingsAssignedEmployeesComponent implements OnInit, OnDestroy {
  public faAngleLeft = faAngleLeft;
  public faCog = faCog;
  public faTimesCircle = faTimesCircle;
  public editable: boolean = false;
  public isLoadingComponent: boolean = false;
  public assignedEmployeesForm: FormGroup;
  public employees;
  public assignedEmployees;
  public defaultSelectValue = this.translateService.instant('COMPONENTS.DASHBOARD.BUILDINGS_ASSIGNED_EMPLOYEES.DEFAULT_SELECT_VALUE');
  public buildingId = this.route.snapshot.paramMap.get('building_id');
  private subscriptions: Subscription[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private employeeService: EmployeeService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  get disableNewEmployeeButton(): boolean {
    return this.assignedEmployeesForm.value.items.some(({ id, userId }) => !id || !userId);
  }

  ngOnInit(): void {
    this.setForm();
    this.employeeService.getEmployees({
      itemsPerPage: 999
    }).pipe(
      mergeMap((response) => {
        this.isLoadingComponent = true;
        this.employees = response.result['hydra:member'];
        return this.employeeService.getUserBuildings({
          'building.id': this.buildingId,
          itemsPerPage: 999
        });
      }),
      tap((response) => {
        this.assignedEmployees = response.result['hydra:member'];
        this.setForm();
      }),
    ).subscribe(() => {
      this.isLoadingComponent = false;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  addEmployee(createdEmployee?) {
    (this.assignedEmployeesForm.get('items') as FormArray).push(createdEmployee ? createdEmployee : this.createEmployeeItem());
  }

  createEmployeeItem(employee?) {
    const form = this.formBuilder.group({
      id: [employee?.id ?? null],
      userId: [employee?.worker.id ?? 'default'],
      MONDAY: employee?.days.includes(1) ?? false,
      TUESDAY: employee?.days.includes(2) ?? false,
      WEDNESDAY: employee?.days.includes(3) ?? false,
      THURSDAY: employee?.days.includes(4) ?? false,
      FRIDAY: employee?.days.includes(5) ?? false,
      SATURDAY: employee?.days.includes(6) ?? false,
      SUNDAY: employee?.days.includes(7) ?? false,
    });

    this.subscriptions.push(
      form.valueChanges.pipe(
        tap(() => this.isLoadingComponent = true),
        debounceTime(1000),
        mergeMap((data) => {
          const entries = Object.entries(data).filter(([key, value]) => value === true);
          const body = {
            worker: data.userId,
            building: this.buildingId,
            days: entries.map(entry => Days[entry[0]])
          };
          return !data.userId
            ? of(null)
            : data.id
              ? this.employeeService.updateEmployeeBuilding(data.id, body)
              : this.employeeService.createEmployeeBuilding(body).pipe(
                tap((newEmployeeBuilding) => form.get('id').setValue(newEmployeeBuilding.id))
              );
        })
      ).subscribe(
        () => this.isLoadingComponent = false,
        () => this.isLoadingComponent = false,
      ),
    );
    return form;
  }

  removeEmployeeBuilding(index: number, buildingEmployee): void {
    this.isLoadingComponent = true;
    const remove = () => {
      (this.assignedEmployeesForm.get('items') as FormArray).removeAt(index);
      this.subscriptions[index].unsubscribe();
      this.subscriptions.splice(index, 1);
      this.isLoadingComponent = false;
    };

    buildingEmployee
      ? this.employeeService.removeEmployeeBuilding(buildingEmployee).pipe(tap(() => remove())).subscribe()
      : remove();
  }

  changeSelectedUser(employeeId) {
    this.employees = this.employees.map(employee => {
      if (employee.id === Number(employeeId)) {
        employee.disabled = true;
      }
      return employee;
    });
  }

  toggleEditable() {
    this.editable = !this.editable;

    if (!this.editable) {
      [...(this.assignedEmployeesForm.get('items') as FormArray).controls].forEach((group: FormGroup, index: number) => {
        if (!group.value.id) {
          this.removeEmployeeBuilding(index, group.value.id);
        }
      });
    }
  }

  employeesFor({ value }): Employee[] {
    return this.employees.filter(employee => {
      return (employee.id === value.userId ||
              !this.assignedEmployeesForm.value.items.find(({ userId }) => employee.id === userId)) &&
              employee.status === 1;
    });
  }

  private setForm(): void {
    this.assignedEmployeesForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    this.setAssignedEmployees();
  }

  private setAssignedEmployees(): void {
    this.assignedEmployees?.forEach((employee) => {
      if (employee.status) {
        this.addEmployee(this.createEmployeeItem(employee));
      }
    });
  }
}
