import { NgModule } from '@angular/core';
import { UsersIndexPageComponent } from './users-index.page';
import { BreadcrumbModule } from '../../../../components/ui-components/breadcrumb/breadcrumb.module';
import { UsersTableModule } from '../../../../components/dashboard/users/users-table/users-table.module';


@NgModule({
  declarations: [UsersIndexPageComponent],
  exports: [UsersIndexPageComponent],
  imports: [
    BreadcrumbModule,
    UsersTableModule
  ]
})
export class UsersIndexPageModule {
}
